import React, { useRef, useState, useEffect } from 'react';
import { Button, ProgressBar, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay, faPause, faMusic } from '@fortawesome/free-solid-svg-icons';

const CustomAudioPlayer = ({ audioSrc, controlsColor, progressBarColor, title, lyrics, colorString }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState('00:00');
    const [duration, setDuration] = useState('00:00');
    const [showModal, setShowModal] = useState(false);

    // Helper to format time from seconds to mm:ss
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Toggle Play/Pause
    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    // Handle Time Update
    const handleTimeUpdate = () => {
        const audio = audioRef.current;
        const progress = (audio.currentTime / audio.duration) * 100;
        setProgress(progress);
        setCurrentTime(formatTime(audio.currentTime));
    };

    // Update Duration once audio metadata is loaded
    const handleLoadedMetadata = () => {
        const audio = audioRef.current;
        setDuration(formatTime(audio.duration));
    };

    // Skip 10 seconds forward
    const skipForward = () => {
        const audio = audioRef.current;
        audio.currentTime = Math.min(audio.currentTime + 10, audio.duration);
    };

    // Skip 10 seconds backward
    const skipBackward = () => {
        const audio = audioRef.current;
        audio.currentTime = Math.max(audio.currentTime - 10, 0);
    };

    // Modal control functions
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const [showAlert, setShowAlert] = useState(false);
    const compartir = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 1500);
            })
            .catch(err => {
                console.error("Error al copiar la URL: ", err);
            });
    }

    return (
        <div className="container mt-4">
            {/* ProgressBar and Time Display */}
            <div className="d-flex justify-content-between align-items-center mt-2">
                <span>{currentTime}</span>
                <ProgressBar
                    now={progress}
                    className="flex-grow-1 mx-2"
                    style={{ height: '10px', backgroundColor: progressBarColor }}
                />
                <span>{duration}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <audio
                    ref={audioRef}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    src={audioSrc}
                />
                {/* Botones */}
                <Button
                    variant="warning"
                    onClick={skipBackward}
                    className="mx-2"
                    style={{ backgroundColor: controlsColor, borderColor: controlsColor }}
                >
                    <FontAwesomeIcon icon={faBackward} /> -10s
                </Button>
                <Button
                    variant="primary"
                    onClick={togglePlayPause}
                    className="mx-2"
                    style={{ backgroundColor: controlsColor, borderColor: controlsColor }}
                >
                    {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
                </Button>
                <Button
                    variant="warning"
                    onClick={skipForward}
                    className="mx-2"
                    style={{ backgroundColor: controlsColor, borderColor: controlsColor }}
                >
                    <FontAwesomeIcon icon={faForward} /> +10s
                </Button>
            </div>



            {/* Button to open modal */}
            {lyrics != "" && <div className="text-center mt-4">

                <div onClick={handleShow}>
                    <img src={`/img/Marchas_${colorString}.png`} className='he-50 pointer' />
                </div>

            </div>}

            {/* Modal with Lyrics and Share button */}
            <Modal show={showModal} onHide={handleClose} centered style={{zIndex:2000}}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {lyrics.split('\n').map((line, index) => (<>
                        {line}
                        <br /></>))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => compartir(lyrics)}
                    >
                        Compartir
                    </Button>
                </Modal.Footer>

                {showAlert && (
                    <div style={{ width: '100%', position: 'absolute', bottom: '30px' }} className='flex j-center'>
                        <Alert variant="success" style={{ zIndex: '2300' }}>
                            ¡Copiado al portapapeles!
                        </Alert>
                    </div>
                )}
            </Modal>


        </div>
    );
};

export default CustomAudioPlayer;
