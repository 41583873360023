import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loading({ classExtra = '', variant = 'info', size = 32, bsSize='md', animation="border" }) {
    return (
        <div className={`align-center-vertically p-0 ${classExtra}`} style={{ height: `${size}`, width: `${size}` }}>
            <Spinner animation={animation} role="status" variant={variant} size={bsSize} />
        </div>
    )
}

export default Loading