import React from "react";
import { BrowserRouter as Router, Switch, Route, Routes } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import './css/navbar.css';

import NavBar from "./componentes/panelAdmin/navbar/NavBar";
import Login from "./componentes/login/Login";
import CrearUnidad from "./componentes/panelAdmin/unidades/CrearUnidad";
import Unidades from "./componentes/panelAdmin/unidades/Unidades";
import CrearEje from "./componentes/panelAdmin/ejes/CrearEje";
/* import UserController from "./componentes/login/UserController"; */
import Usuarios from "./componentes/panelAdmin/usuarios/Usuarios";
import CrearUsuario from "./componentes/panelAdmin/usuarios/CrearUsuario";
import ShowNovedades from "./componentes/panelAdmin/novedades/ShowNovedades";
import CreateNovedad from "./componentes/panelAdmin/novedades/CreateNovedad";
import CreateNotificacion from "./componentes/panelAdmin/notificaciones/CreateNotificacion";
import Home from "./componentes/home/Home";

import '../src/css/app.css'
import Unidad from "./componentes/unidades/Unidad";
import UnidadesPorEje from "./componentes/unidades/UnidadesPorEje";
import Busqueda from "./componentes/home/Busqueda";
import UnidadesGuardadas from "./componentes/unidades/UnidadesGuardadas.js";
import Settings from "./componentes/panelAdmin/settings/Settings.js";
import ShowNotifications from "./componentes/panelAdmin/notificaciones/Notifications";
import UserController from "./componentes/login/UserController.js";

export function App() {
    return (
        <div>
            <Router>
                <Row className="m-0">
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route exact path="/unidad">
                            <Unidad/>
                        </Route>
                        <Route exact path="/eje">
                            <UnidadesPorEje/>
                        </Route>
                        <Route exact path="/login">
                            <Login />
                        </Route>
                        <Route exact path="/busqueda">
                            <Busqueda />
                        </Route>
                        <Route exact path="/unidades-guardadas">
                            <UnidadesGuardadas />
                        </Route>
                        <Route exact path="/unidades">

                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <Unidades />
                            </Col>
                        </Route>
                        <Route exact path="/unidades/nueva">
                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <CrearUnidad />
                            </Col>
                        </Route>
                        <Route exact path="/unidades/modificar">
                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <CrearUnidad />
                            </Col>
                        </Route>
                        <Route exact path="/ejes/nuevo">
                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <CrearEje />
                            </Col>
                        </Route>
                        <Route exact path="/usuarios">
                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <Usuarios />
                            </Col>
                        </Route>
                        <Route exact path="/usuarios/nuevo">
                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <CrearUsuario />
                            </Col>
                        </Route>
                        <Route exact path="/novedades">
                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <ShowNovedades />
                            </Col>
                        </Route>
                        <Route exact path="/novedades/nueva">
                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <CreateNovedad />
                            </Col>
                        </Route>
                        <Route exact path="/notificaciones">
                            <Col xs={2} style={{ position: "fixed", height: "100%", background: "#183153", paddingRight: 0 }}>
                                <NavBar />
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <CreateNotificacion />
                            </Col>
                        </Route>
                        <Route exact path="/notificaciones/log">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <ShowNotifications/>
                            </Col>
                        </Route>

                        <Route exact path="/configuracion">
                            <Col xs={2} style={{position:"fixed", height:"100%", background:"#183153", paddingRight: 0}}>
                                <NavBar/>
                            </Col>
                            <Col xs={2}>

                            </Col>
                            <Col xs={10} className="pt-4">
                                <UserController/>
                                <Settings/>
                            </Col>
                        </Route>
                    </Switch>


                </Row>
            </Router>


            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
        </div>

    )
}