import React, {useEffect} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Button, Container, Row, Col, Card, Form} from "react-bootstrap";
import {obtenerUnidades, eliminarUnidad, obtenerUnidadesAll} from '../../../controlador/index.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
//import convertirABase64 from '../tools/convertirABase64';

export default function CrearUnidad(){
    const rutaArchivos = "https://unit.fasta.org/data";
    const [unidades, setUnidades] = React.useState([]);
    const [searchedUnidades, setSearchedUnidades] = React.useState([]);
    const [searchChain, setSearchChain] = React.useState('');

    useEffect(() => {
        document.title = "Unit - Unidades";
        unidadesExistentes();
        showLoading();
    }, []);

    const showLoading = function(){
        document.getElementById('unidadesMsg').innerHTML = '<div class="d-flex justify-content-center mt-5"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
    }

    const unidadesExistentes = async function(){
        const token = localStorage.getItem("user-token")
        const getUnidades = await obtenerUnidadesAll(token);
        if(getUnidades.status === 200){
            const u = await getUnidades.json();
            setUnidades(u);
            setSearchedUnidades(u);
            verificarUnidades(u);
        }else if(getUnidades.status === 403){
            window.location.href = "/login";
        }
    }

    const handleEliminar = (unidadId, unidadNombre) => {
        eliminar(unidadId, unidadNombre);
    };

    const handleSearch = (event) => {
        setSearchChain(event.target.value);
    };

    const eliminar = async function(unidadId, unidadNombre){
        if (window.confirm("¿Estás seguro de eliminar la unidad " + unidadNombre + "?")) {
            const token = localStorage.getItem("user-token");
            const deleteUnidad = await eliminarUnidad(token, unidadId);

            if(deleteUnidad.status === 200){
                alert("Unidad eliminada correctamente");
                window.location.reload();
            }else{
                alert("Error al eliminar unidad. " + deleteUnidad.data + " " + deleteUnidad.message);
            }
        }
    }

    const verificarUnidades = function(un){
        if(un.length === 0){
            document.getElementById('unidadesMsg').innerHTML = '<p class="mt-5">No hay unidades existentes</p>'
        }else{
            document.getElementById('unidadesMsg').innerHTML = '';
        }
    }

    const removeTildes = function(chain){
        return chain.replaceAll("á", "a").replaceAll("é", "e").replaceAll("í", "i").replaceAll("ó", "o").replaceAll("ú", "u");
    }

    const search = function(){
        let result = [];
        let chain = '';

        if(searchChain.length > 0){
            chain = removeTildes(searchChain);
        }

        for(let u of unidades){
            if(chain.length > 0){
                if(removeTildes(u.etiquetas).replace(";", "").includes(chain.toLowerCase())){
                    result.push(u);
                }else if(removeTildes(u.nombre).toLowerCase().includes(chain.toLowerCase())){
                    result.push(u);
                }
            }else{
                result.push(u);
            }
        }
        setSearchedUnidades(result);
        verificarUnidades(result);
    }

    return(
        <Container>
            <Row className="mb-4">
                <Col>
                    <h1>Unidades</h1>
                </Col>
                <Col style={{textAlign: "right"}} className="mt-2">
                    <Link to={"/unidades/nueva"}>
                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 5}} />Crear nueva unidad</Button>
                    </Link>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col xs={4}><Form.Control onChange = {(event) => handleSearch(event)} type="text" id="text_search" placeholder="" /></Col>
                <Col><Button variant="primary" onClick={search}>Buscar</Button></Col>
            </Row>

            <hr></hr>

            <Row className='mt-4'>
                <div id='unidadesMsg' style={{width:"100%", textAlign:"center", fontSize:25}}></div>
                {searchedUnidades.map((u) => (
                    <Col xs={3} className="mb-3">
                        <Card>
                            <Card.Img variant="top" src={rutaArchivos + u.imagen.uri} height={200} />
                            <Card.Body>
                                
                                <Card.Title>{u.nombre}</Card.Title>
                                <Card.Text>
                                    <hr></hr>
                                    <p>
                                        {u.unidades_ejes.map((ue) => (
                                            <p className="m-0">{ue.eje.nombre}</p>
                                        ))}
                                    </p>
                                    <hr></hr>
                                    <p>
                                        {u.unidades_secciones.map((us) => (
                                            <p className="m-0">{`${us.secciones_departamento.nombre}`}</p>
                                        ))}
                                    </p>
                                    <hr></hr>
                                </Card.Text>
                                <Link to={`/unidades/modificar/?unidad=${u.id}`}>
                                    <Button style={{marginRight:10}} className="w-25" variant="warning"><FontAwesomeIcon icon={faPen} /></Button>
                                </Link>

                                <Button variant="danger" className="w-25" onClick={() => handleEliminar(u.id, u.nombre)} href="#"><FontAwesomeIcon icon={faTrash} /></Button>
                            </Card.Body>
                        </Card>
                    </Col>

                ))}
              
            </Row>
        </Container>
    )

}
