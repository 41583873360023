export default function ViewImages(files){
    let img = '<div class="row">';
    for(let j = 0; j < files.length; j++){
        if(j < 4){
            img += '<div class="col-3"><div class="mt-4"><img style="width: 100%" src="' + files[j] + '"/></div></div>';
        }
    }
    img += '</div>';
    if(files.length > 4){
        img += '<div class="row">';
        for(let k = 4; k < files.length; k++){
            if(k < 8){
                img += '<div class="col-3"><div class="mt-4"><img style="width: 100%" src="' + files[k] + '"/></div></div>';
            }
        }
        img += '</div>';
    }
    img += '<div class="mt-4"></div>';
    return img;
}