import React from 'react'
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { login } from '../../controlador/index.controller';
import { validarUsuario } from './UserController';

export default function Login() {
    const [correo, setCorreo] = React.useState('');
    const [contrasena, setContrasena] = React.useState('');

    const handleCorreo = (event) => {
        setCorreo(event.target.value);
    };

    const handleContrasena = (event) => {
        setContrasena(event.target.value);
    };

    const ingresar = async function () {
        const usuario = {
            correo: correo,
            contrasena: contrasena
        }

        let postLogin = await login(usuario);
        let response = await postLogin.json()

        if (postLogin.status === 200) {
            guardarDatos(response);
            validarUsuario();
        } else {
            alert("Error al iniciar sesión");
        }
    }

    const guardarDatos = function (datos) {
        localStorage.setItem("user-token", datos.token);
        localStorage.setItem("user-nombre", datos.nombre);
        localStorage.setItem("user-apellido", datos.apellido);
        localStorage.setItem("user-id", datos.id);
        localStorage.setItem("user-role", datos.rol);
        window.location.href = "/";
    }


    return (
        <div style={{ height: '100vh' }} className='flex a-center'>
            <div className="gradientBackTransparent" style={{ height: '35vh' }} />

            <Container>
                <Row>
                    <div className='flex j-center'>
                        <img style={{ height: '70px' }} src="/img/LogoNegro.png" />

                    </div>

                </Row>

                <Row className='mt-5'>
                    <Col xs={4}>

                    </Col>
                    <Col xs={4} className=''>
                        <Form className="login-form">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="email"
                                    onChange={(event) => handleCorreo(event)}
                                    placeholder="Correo electrónico"
                                    className="form-input"
                                />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formBasicPassword">
                                <Form.Control
                                    type="password"
                                    onChange={(event) => handleContrasena(event)}
                                    placeholder="Contraseña"
                                    className="form-input"
                                />
                            </Form.Group>

                            <Button
                                variant="primary"
                                type="submit"
                                className="w-100 btn-ingresar"
                                href={"#"}
                                onClick={ingresar}
                            >
                                Ingresar
                            </Button>
                        </Form>
                    </Col>
                    <Col xs={4}>

                    </Col>
                </Row>
            </Container>

        </div>
    );
}
