import React, {useEffect} from 'react'
import {Button, Container, Row, Col, Card} from "react-bootstrap";
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import {obtenerNovedades, put} from '../../../controlador/index.controller';


export default function ShowNovedades(){
    const rutaArchivos = "https://unit.fasta.org/data";
    const [novedades, setNovedades] = React.useState([]);

    useEffect(() => {
        document.title = "Unit - Novedades";
        novedadesExistentes();
    }, []);

    const novedadesExistentes = async function(){
        const token = localStorage.getItem("user-token")
        const n = await obtenerNovedades(token);
        setNovedades(n);
    }

    const handleEliminar = (novedadId) => {
        deleteNovedad(novedadId);
    };

    const deleteNovedad = async function(novedadId){
        if (window.confirm("¿Estás seguro de eliminar la novedad?")) {
            const data = {
                novedadId: novedadId
            };
            const token = localStorage.getItem("user-token");
            let request = await put(`api/novedades/mostrar/${novedadId}/false`, token, data);
            const response = await request.json();

            if(request.status === 201){
                alert("Novedad eliminada correctamente");
                window.location.reload();
            }else{
                alert("Error al eliminar novedad. " + response.data + " " + response.message);
            }
        }
    }

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1>Novedades</h1>
                    </Col>
                    <Col style={{textAlign: "right"}} className="mt-2">
                        <Link to={"/novedades/nueva"}>
                            <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 5}} />Crear nueva novedad</Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                   
                    {novedades.map((n) => (
                        <Col xs={4} className="mb-3">
                            <Card>
                                <Card.Img variant="top" src={rutaArchivos + n.imagen.uri} height={250} />
                                <Card.Body>
                                    <Card.Text>
                                        <p>{n.texto}</p>
                                    </Card.Text>

                                    <Button variant="danger" onClick={() => handleEliminar(n.id)} href="#"><FontAwesomeIcon icon={faTrash} style={{marginRight: 5}} />Eliminar</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                    ))}
                
                </Row>
            </Container>
        </div>
    );
}