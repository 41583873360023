import React, {useEffect} from 'react'
import {Form, Button, Container, Row, Col, Table} from "react-bootstrap";
import {crearEje, obtenerEjes} from '../../../controlador/index.controller';

export default function CrearEje(){
    const [nombre, setNombre] = React.useState('');
    const [habilitado, setHabilitado] = React.useState('');
    const [icono, setIcono] = React.useState('');
    const [ejes = [], setEjes] = React.useState();

   
    useEffect(() => {
        ejesExistentes();
    }, []);
  

    const handleNombre = (event) => {
        setNombre(event.target.value);
    }

    const handleHabilitado = (event) => {
        setHabilitado(event.target.checked);
    }

    const handleIcono = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setIcono(base64);
    }     

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const crear = async function(){
        let habAux;
        if(habilitado === ""){
            habAux = true;
        }else{
            habAux = habilitado;
        }

        let eje = {
            nombre: nombre,
            icono: icono,
            habilitado: habAux
        };

        const token = localStorage.getItem("user-token");
        let postEje = await crearEje(token, eje);

        if(postEje === 201){
            alert("Eje creado correctamente");
        }else{
            alert("Error al crear el eje");
        }
    };

    const ejesExistentes = async function(){
        const token = localStorage.getItem("user-token");
        let getEjes = await obtenerEjes(token);
        const data = getEjes.data;
        let ejesAux = [];
        let eje;

        for(let i = 0; i < data.length; i++){
            eje = data[i];
            ejesAux.push({
                icono: eje.icono,
                nombre: eje.nombre,
                mostrar: eje.habilitado === true ? "Si" : "No"
            });
        }

        setEjes(ejesAux);
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h1>Crear eje</h1>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>Nombre del eje</Form.Label>
                                <Form.Control onChange = {(event) => handleNombre(event)} type="text" id="" placeholder="Nombre" />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                    <Form.Label>Icono</Form.Label>
                                    <Form.Control onChange = {(event) => handleIcono(event)} type="file" />
                                </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Check onChange = {(event) => handleHabilitado(event)} id="check1" type="checkbox" label="Mostrar en App" defaultChecked/>
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Button variant="primary" href={"#"} onClick={crear}>Guardar</Button>{' '}
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col>
                        <div className="mb-4">
                            <h1>Ejes existentes</h1>
                        </div>
                        
                        <div className="mb-3">
                            <Table bordered>
                                <thead style={{backgroundColor: '#0D6EFD'}}>
                                    <tr>
                                        <th style = {{color:'#fff', fontSize: '15px', fontWeight:'bold', textAlign: 'center'}}>Icono</th>
                                        <th style = {{color:'#fff', fontSize: '15px', fontWeight:'bold', textAlign: 'center'}}>Nombre</th>
                                        <th style = {{color:'#fff', fontSize: '15px', fontWeight:'bold', textAlign: 'center'}}>Mostrar en App</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ejes.map((e) => (
                                        <tr key={e.id}>
                                            <td align="center" style={{verticalAlign: 'middle'}}><img height="50" alt="" src={e.icono}/></td>
                                            <td align="center" style={{verticalAlign: 'middle'}}>{e.nombre}</td>
                                            <td align="center" style={{verticalAlign: 'middle'}}>{e.mostrar}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
            
        </div>
    )
    
}
