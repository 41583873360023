import React, { useEffect, useState } from 'react'
import NavBarGeneral from '../navbar/NavBar'
import { getUserRole, getUserToken, validarUsuario } from '../login/UserController';
import { obtenerEjes, obtenerNovedades, obtenerUnidades, obtenerUnidadesPorEje, obtenerMyData, buscarUnidad } from '../../controlador/index.controller';
import { Carousel, Col, Row, Container, Button, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay, faPause, faMusic, faSearch } from '@fortawesome/free-solid-svg-icons';
import Loading from '../generalComponents/Loading';
import Footer from '../generalComponents/Footer';

function Home() {

    const rutaArchivos = "https://unit.fasta.org/data";
    useEffect(() => {
        document.title = "Inicio";
        validarUsuario();
        getNovedades()
        getEjes();
        getUnidadesList();
        obtenerMyData()
    }, []);

    const [novedadesCargadas, setNovedadesCargadas] = useState(false);
    const [novedades, setNovedades] = useState([
        {
            "id": 29,
            "mostrar": true,
            "imagenId": 1472,
            "accion": null,
            "accionTipo": 0,
            "novedadTipo": 1,
            "texto": null,
            "createdAt": "2023-05-05T13:45:29.000Z",
            "updatedAt": "2023-05-05T13:45:29.000Z",
            "imagen": {
                "id": 1472,
                "uri": "https://www.omintassistance.com.ar/blog/wp-content/uploads/2018/01/Grecia.jpg",
                "tipo": 0,
                "createdAt": "2023-05-05T13:45:29.000Z",
                "updatedAt": "2023-05-05T13:45:29.000Z"
            }
        },
        {
            "id": 29,
            "mostrar": true,
            "imagenId": 1472,
            "accion": null,
            "accionTipo": 0,
            "novedadTipo": 1,
            "texto": null,
            "createdAt": "2023-05-05T13:45:29.000Z",
            "updatedAt": "2023-05-05T13:45:29.000Z",
            "imagen": {
                "id": 1472,
                "uri": "https://cms.w2m.com/dam/Sites/Flowo/imagenes-blog/grecia-en-una-semana/que-ver-grecia-en-una-semana-Mykonos-4.webp",
                "tipo": 0,
                "createdAt": "2023-05-05T13:45:29.000Z",
                "updatedAt": "2023-05-05T13:45:29.000Z"
            }
        }
    ]);
    const getNovedades = async () => {
        setNovedadesCargadas(false);
        const res = await obtenerNovedades(getUserToken());
        setNovedades(res);
        setNovedadesCargadas(true);
    }

    const [myData, setMyData] = useState(null)
    const getMyData = async () => {
        const res = await obtenerMyData(getUserToken());
        console.log(res)
        setMyData(res);
    }

    const [ejes, setEjes] = useState(null)
    const getEjes = async () => {
        const res = await obtenerEjes(getUserToken());
        console.log(res)
        setEjes(res);
    }

    const [unidades, setUnidades] = useState(null)
    const [unidadesCargadas, setUnidadesCargadas] = useState(false);
    const getUnidadesList = async () => {
        setUnidadesCargadas(false)
        const getUnidades = await obtenerUnidades(getUserToken());
        setUnidadesCargadas(true);
        if (getUnidades.status === 200) {
            const u = await getUnidades.json();
            let arr = u.slice(-8).reverse();
            arr.map((t, j) => {
                let arrStateImg = []
                t.unidades_secciones.map((r, i) => {
                    let id = r.seccionId;
                    if (imgSecciones[r.seccionId - 1].src == null) {
                        id = imgSecciones[r.seccionId - 1].img;
                    }
                    if (!arrStateImg.includes(id)) {
                        r.img = imgSecciones[id - 1].src;
                        arrStateImg.push(id);
                    } else {
                        if (id != r.seccionId) {
                            r.img = null;
                        }
                    }
                })
            })
            setUnidades(arr)
            console.log(arr)
        } else if (getUnidades.status === 403) {
            window.location.href = "/login";
        }
    };

    const imgSecciones = [
        { id: 1, src: '/img/Caperucitas.png' },
        { id: 2, src: null, img: 1 },
        { id: 3, src: '/img/Herederas.png' },
        { id: 4, src: '/img/Adalides.png' },
        { id: 5, src: null, img: 4 },
        { id: 6, src: '/img/Escuderos.png' },
        { id: 7, src: null, img: 6 },
        { id: 8, src: '/img/Templarios.png' },
        { id: 9, src: null, img: 8 },
    ]

    const SearchBar = () => {

        const handleSearchChange = async () => {
            const chain = document.getElementById(`textSearch`).value;
            window.location.href = `/busqueda?ch=${chain}`;
        };

        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSearchChange()
            }
        };

        return (
            <Col className=''>
                <div className='align-center-vertically '>
                    <div className='mb-1'>
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="searchDiv">
                            <Form.Control
                                className='searchBar fs-14 wi-200'
                                autoComplete="off"
                                id={`textSearch`}
                                placeholder={"Buscar..."}
                                onKeyDown={handleKeyPress}
                            />
                            <div onClick={() => handleSearchChange()} className='ms-2 flex a-center'>
                                <FontAwesomeIcon icon={faSearch} style={{ color: 'grey' }} />
                            </div>

                        </div>
                    </div>
                </div>
            </Col>
        );
    };

    return (
        <>
            <div className='content ps-0 pe-0 d-none d-sm-block'>

                <NavBarGeneral />
                <div className='ps-5 pe-5 pt-3' style={{minHeight:'90vh'}}>
                    <div className="gradientBackTransparent" style={{ marginTop: '70px' }} />

                    <Row className='p-0'>
                        <Col md={12}>
                            <Carousel className="mb-3" interval={7000} fade>
                                {!novedadesCargadas ?
                                    <div className='flex j-center a-center mt-3' style={{ height: '40vh' }}>
                                        <Loading />
                                    </div>
                                    :
                                    novedades != null && novedades.map((r, i) => r.mostrar && (
                                        <Carousel.Item key={i} style={{ overflow: 'hidden', backgroundColor: '#f0f0f080' }} className='p-2 br-15'>
                                            <div style={{ overflow: 'hidden' }} className='w-100 br-15 flex j-center'>
                                                <img
                                                    className="d-block "
                                                    src={`${rutaArchivos}${r.imagen.uri}`}
                                                    alt=""
                                                    style={{ height: '40vh' }}
                                                />
                                            </div>
                                            <Carousel.Caption>
                                                <h3>{r.texto && r.texto}</h3>
                                            </Carousel.Caption>
                                        </Carousel.Item>))}
                            </Carousel>
                        </Col>
                        <Col md={12}>
                            <div className='flex j-center' style={{ columnGap: '20px' }}>
                                {ejes !== null && ejes.length > 0 && ejes.map((r, i) => r.habilitado && (
                                    <Link to={`/eje/?id=${r.id}`}><div className='flex a-center fitContentWidth' style={{ flexDirection: 'column' }}>
                                        <div style={{ borderRadius: '50%', border: '1px solid #dddddd' }} className='p-2 mb-1'>
                                            <img style={{ height: '80px' }} src={`${rutaArchivos}${r.iconoUri}`} />
                                        </div>
                                        <p className='fs-14' style={{ wordWrap: 'normal', width: 'min-content', textAlign: 'center', lineHeight: '19px', color: 'black' }}>{r.nombre}</p>
                                    </div></Link>
                                ))}
                            </div>
                        </Col>
                    </Row>
                    <Container>
                        <Row className='mt-2'>
                            <p className='fw-600 fs-18'>Temas recomendados para vos</p>
                            {unidades != null && unidadesCargadas ? unidades.map((r, i) => (
                                <Col md={6} className='mb-3'>
                                    <div className=''>
                                        <Link to={`unidad?id=${r.id}`}><Row className='br-15 boxShadow' style={{ maxHeight: '180px', overflow: 'hidden' }}>
                                            <Col md={6} className='ps-3 pt-3 pb-3 flex' style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <div>
                                                    <div className='fs-14 ps-2 pe-2 gradientBack fitContentWidth' style={{ borderRadius: '10px', color: 'white' }} >
                                                        {r.unidades_ejes[0].eje.nombre}
                                                    </div>
                                                    <p className='fw-600 fs-18 mt-2' style={{ color: 'black' }}>{r.nombre}</p>
                                                </div>
                                                <p className='mb-0 fs-14 grey'>{`${"#" + r.etiquetas.split("; ").join(" #")}`}</p>
                                            </Col>
                                            <Col md={6} className='pe-0' style={{ height: '180px', overflow: 'hidden', position: 'relative' }}>
                                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${rutaArchivos}${r.imagen.uri}`} alt={r.nombre} />

                                                <div style={{ position: 'absolute', bottom: '5px', right: '5px', backgroundColor: '#ffffffed', columnGap: '4px' }} className='p-1 br-15 flex a-center'>
                                                    {r.unidades_secciones.map((s, i) => s.img != null && (
                                                        <img style={{ height: '20px' }} src={s.img} />
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row></Link>
                                    </div>
                                </Col>
                            )) :
                                <div className='flex j-center a-center mt-3'>
                                    <Loading />
                                </div>
                            }
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div >

            <div className='d-block d-sm-none' style={{ height: '100vh' }}>
                <div style={{ height: '95vh', width: '95vw' }} className="p-3 flex a-center j-center">
                    <Card className='flex j-center gradientBack p-3'>
                        <img style={{ height: '70px' }} src="https://unit.fasta.org/img/logo.png" />

                        <p style={{ textAlign: 'center' }}>Instalate la app</p>

                        <a style={{ textAlign: 'center' }} href="https://play.google.com/store/apps/details?id=com.secdevs.fasta.unit&pcampaignid=web_share" target="blank"><Button variant="light">
                            Google PlayStore
                        </Button></a>

                        <a style={{ textAlign: 'center' }} href='https://apps.apple.com/ar/app/unit/id1622199142?l=en-GB'><Button variant="light" className='mt-3'>
                            AppStore
                        </Button></a>
                    </Card>
                </div>

            </div>
        </>
    )
}

export default Home