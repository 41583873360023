import React, {useEffect} from 'react'
import {Button, Container, Row, Col, Form} from "react-bootstrap";
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {get, put} from '../../../controlador/index.controller';


export default function Settings(){
    const [settings, setSettings] = React.useState([]);

    useEffect(() => {
        document.title = "Unit - Configuración";
        getSettings();
    }, []);

    const getSettings = async function(){
        let request = await get("api/config", null);
        const response = await request.json()
        setSettings(response);
        showSettingsValues(response);
    }

    const showSettingsValues = function(response){
        let APP_ANDROID_LAST_VERSION = '';
        let APP_ANDROID_DOWNLOAD_URL = '';
        let APP_APPLE_LAST_VERSION = '';
        let APP_APPLE_DOWNLOAD_URL = '';
        let USER_ENABLED_BY_DEFAULT = '';

        for(let s of response){
            if(s.param === 'APP_ANDROID_LAST_VERSION'){APP_ANDROID_LAST_VERSION = s.value;}
            if(s.param === 'APP_ANDROID_DOWNLOAD_URL'){APP_ANDROID_DOWNLOAD_URL = s.value;}
            if(s.param === 'APP_APPLE_LAST_VERSION'){APP_APPLE_LAST_VERSION = s.value;}
            if(s.param === 'APP_APPLE_DOWNLOAD_URL'){APP_APPLE_DOWNLOAD_URL = s.value;}
            if(s.param === 'USER_ENABLED_BY_DEFAULT'){USER_ENABLED_BY_DEFAULT = s.value;}
        }

        document.getElementById('text_APP_ANDROID_LAST_VERSION').value = APP_ANDROID_LAST_VERSION;
        document.getElementById('text_APP_ANDROID_DOWNLOAD_URL').value = APP_ANDROID_DOWNLOAD_URL;
        document.getElementById('text_APP_APPLE_LAST_VERSION').value = APP_APPLE_LAST_VERSION;
        document.getElementById('text_APP_APPLE_DOWNLOAD_URL').value = APP_APPLE_DOWNLOAD_URL;
        document.getElementById('text_USER_ENABLED_BY_DEFAULT').value = USER_ENABLED_BY_DEFAULT;
    }

    const handleSettings = (event) => {
        let APP_ANDROID_LAST_VERSION = document.getElementById('text_APP_ANDROID_LAST_VERSION').value;
        let APP_ANDROID_DOWNLOAD_URL = document.getElementById('text_APP_ANDROID_DOWNLOAD_URL').value;
        let APP_APPLE_LAST_VERSION = document.getElementById('text_APP_APPLE_LAST_VERSION').value;
        let APP_APPLE_DOWNLOAD_URL = document.getElementById('text_APP_APPLE_DOWNLOAD_URL').value;
        let USER_ENABLED_BY_DEFAULT = document.getElementById('text_USER_ENABLED_BY_DEFAULT').value;

        let body = [];

        for(let s of settings){
            if(s.param === 'APP_ANDROID_LAST_VERSION'){s.value = APP_ANDROID_LAST_VERSION;}
            if(s.param === 'APP_ANDROID_DOWNLOAD_URL'){s.value = APP_ANDROID_DOWNLOAD_URL;}
            if(s.param === 'APP_APPLE_LAST_VERSION'){s.value = APP_APPLE_LAST_VERSION;}
            if(s.param === 'APP_APPLE_DOWNLOAD_URL'){s.value = APP_APPLE_DOWNLOAD_URL;}
            if(s.param === 'USER_ENABLED_BY_DEFAULT'){s.value = USER_ENABLED_BY_DEFAULT;}

            delete s.createdAt;
            delete s.updatedAt;

            body.push(s);
        }

        setSettings(body);
    };

    const saveSettings = async function(){
        if (window.confirm("¿Estás seguro de guardar los cambios?")) {
            const data = settings;
            const token = localStorage.getItem("user-token");
            let request = await put(`api/config`, token, data);
            const response = await request.json();

            if(request.status === 201){
                alert("Configuración modificada");
                window.location.reload();
            }else{
                alert("Error al modificar configuración. " + response.data + " " + response.message);
            }
        }
    }

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1>Configuración</h1>
                    </Col>
                </Row>

                <Row>
                {settings.map((s) => (
                    <Row>
                        <Col xs={4} className="mb-3">
                            {s.param}
                        </Col>
                        <Col className="mb-3">
                            <Form.Control onChange = {(event) => handleSettings(event)} type="text" id={`text_${s.param}`} placeholder="" />
                        </Col>
                    </Row>
                ))}

                <Form.Group className="mb-5 mt-5" >
                    <Button variant="success" href={"#"} onClick={saveSettings}>Guardar</Button>{' '}
                </Form.Group>
                    
                </Row>
            </Container>
        </div>
    );
}