import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getUserRole, getUserToken, logout, validarUsuario } from '../login/UserController';
import { obtenerEjes, obtenerNovedades, obtenerUnidades, obtenerUnidadesPorEje, buscarUnidad, obtenerSecciones, obtenerMyData } from '../../controlador/index.controller';
import { Carousel, Col, Row, Container, Button, Card, Form, Modal, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay, faPause, faMusic, faSearch, faFilter, faSadCry } from '@fortawesome/free-solid-svg-icons';


function NavBarGeneral({ eje = "", defaultValue = "", seccionesBusq="" }) {
    const [secciones, setSecciones] = useState(null)
    const getSecciones = async () => {
        const res = await obtenerSecciones(getUserToken());
        console.log(res)
        setSecciones(res.data);
    }

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => {
        setShowModal(true)
        setSelectedIds(seccionesBusquedaIds)
    };
    const [selectedIds, setSelectedIds] = useState([]);

    const handleCheckChange = (e, id) => {
        const { checked } = e.target;
        setSelectedIds((prevSelected) => {
            if (checked) {
                return [...prevSelected, id];
            } else {
                return prevSelected.filter((item) => item !== id);
            }
        });
    };

    const handleConfirm = () => {
        setSeccionesBusquedaIds(selectedIds);
        handleSearchChange(selectedIds)
        handleClose()
    };

    const handleSelectAll = (selectAll) => {
        if (selectAll) {
            setSelectedIds(secciones.map((option) => option.id));
        } else {
            setSelectedIds([]);
        }
    };

    const [seccionesBusquedaIds, setSeccionesBusquedaIds] = useState([]);
    const [myData, setMyData] = useState(null)
    const getMyData = async () => {
        const res = await obtenerMyData(getUserToken());
        console.log(res)
        setMyData(res);
        let a = [];
        res.usuarios_secciones.map((r, i) => {
            a.push(r.seccionId);
        })
        setSeccionesBusquedaIds(a);
        setSelectedIds(seccionesBusquedaIds);
    }

    const getSearchUrl = (chain, secciones) => {
        //ch=chain, sc=secciones, j=eje
        const arraySecciones = secciones.join(',');
        if (eje != "") {
            return `/busqueda?ch=${chain}&sc=${arraySecciones}&j=${eje}`
        }
        return `/busqueda?ch=${chain}&sc=${arraySecciones}`
    }

    const handleSearchChange = async (secciones) => {
        const chain = document.getElementById(`textSearch`).value;
        if (chain.length > 3) {
            let a = getSearchUrl(chain, secciones)
            window.location.href = a;
        }

    };

    const SearchBar = () => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSearchChange(seccionesBusquedaIds)
            }
        };

        return (
            <Col className='' style={{ maxWidth: '45vw' }}>
                <div className='align-center-vertically '>
                    <div className='mb-1'>
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="searchDiv">
                            <div onClick={() => handleSearchChange(seccionesBusquedaIds)} className='ms-2 flex a-center'>
                                <FontAwesomeIcon icon={faSearch} style={{ color: 'grey' }} />
                            </div>
                            <Form.Control
                                className='searchBar fs-14 wi-200'
                                autoComplete="off"
                                id={`textSearch`}
                                placeholder={"Buscar..."}
                                onKeyDown={handleKeyPress}
                                style={{ height: '45px' }}
                                defaultValue={defaultValue}
                            />
                            <div onClick={() => handleShow()} className='ms-2 flex a-center pointer'>
                                <FontAwesomeIcon icon={faFilter} style={{ color: 'grey' }} />
                            </div>

                        </div>
                    </div>
                </div>
            </Col>
        );
    };

    useEffect(() => {
        validarUsuario();
        getSecciones();
        if(seccionesBusq!=""){
            let r = seccionesBusq.map((t) => parseInt(t));
            setSeccionesBusquedaIds(r);
            setSelectedIds(r);
        }else{
            getMyData();
        }
    }, []);

    return (<>
        <div className='wi-100vw flex a-center j-spaceBt gradientBack ps-2 pe-2 navbarComponent' >
            <div>
                <Link to='/'>
                    <img style={{ height: '70px', marginLeft: '-25px' }} src="https://unit.fasta.org/img/logo.png" />
                </Link>
            </div>

            <SearchBar />

            <div className='flex a-center' style={{ paddingLeft: '30px' }}>
                {getUserRole() == 1 && <Link to="/unidades"><Button style={{}} variant="light">
                    Panel de administración
                </Button></Link>}

                <Link to={'/unidades-guardadas'}><div className='fitContentWidth ps-3'>
                    <img style={{ height: '50px' }} src="/img/Guardar.png" />
                </div></Link>
                <div className='fitContentWidth'>
                    <Dropdown className='p-0'>
                        <Dropdown.Toggle variant="" id="dropdown-custom" className="dropdown-toggle-custom p-0">
                            <img style={{ height: '50px' }} src="/img/Perfil.png" alt="Perfil" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={logout}>Cerrar sesión</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            </div>
        </div>

        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar unidades</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Seleccioná las secciones por las que quieras filtrar las unidades buscadas</p>
                {secciones && secciones.map((r, i) => (
                    <Form.Check
                        type="checkbox"
                        id={r.id}
                        label={r.nombre}
                        onChange={(e) => handleCheckChange(e, r.id)}
                        checked={selectedIds.includes(r.id)}
                    />
                ))}
                {secciones && secciones.length == selectedIds.length ?
                    <p style={{ textAlign: 'center', color: "#46F0AF" }} className='mt-2 mb-2 pointer' onClick={() => handleSelectAll(false)}>DESELECCIONAR TODO</p>

                    : <p style={{ textAlign: 'center', color: "#46F0AF" }} className='mt-2 mb-2 pointer' onClick={() => handleSelectAll(true)}>SELECCIONAR TODO</p>

                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                /* onClick={() => {
                    navigator.clipboard.writeText('Check out this song: [Link]');
                    alert('Link copiado');
                }} */
                >
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal></>
    )
}

export default NavBarGeneral