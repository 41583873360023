import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Footer() {
    return (
        <div className='gradientBack mt-3 p-4 pt-5' style={{ color: 'white' }}>
            <Container>
                <Row>
                    <Col>
                        <div className='flex'>
                            <img style={{ height: '60px' }} src="/img/Fasta_Blanco.png" />
                        </div>
                    </Col>
                    <Col className='flex a-center' style={{ justifyContent: 'flex-end' }}>
                        <div className='flex a-center' style={{ columnGap: '8px', justifyContent: 'flex-end' }}>
                            <p className='fw-600 mb-0'>Sigue en nuestras redes{` >`}</p>
                            <a href="https://www.facebook.com/MovimientoFasta/" target="blank"><img style={{ height: '30px' }} src="/img/facebook.png" /></a>
                            <a href="https://www.instagram.com/movimientofasta" target="blank"><img style={{ height: '30px' }} src="/img/instagram.png" /></a>
                            <a href="https://www.youtube.com/c/FastaMovimiento" target="blank"><img style={{ height: '30px' }} src="/img/youtube.png" /></a>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='flex j-center'>
                        <img style={{ height: '35px' }} src="/img/Fasta_small.png" />
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default Footer