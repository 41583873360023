import React, {useEffect} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Form, Button, Container, Row, Col} from "react-bootstrap";
import {post, get} from '../../../controlador/index.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'

export default function CreateNotificacion(){

    const [title, setTitle] = React.useState('');
    const [body, setBody] = React.useState('');
    const [secciones = [], setSecciones] = React.useState();
    const [seccionesSeleccionadas = [], setSeccionesSeleccionadas] = React.useState();
    const [osMarcas] = React.useState(["Android", "iOS"]);
    const [osMarcasSeleccionadas = [], setOsMarcasSeleccionadas] = React.useState();

    const handleTitle = (event) => {
        setTitle(event.target.value);
    };

    const handleBody = (event) => {
        setBody(event.target.value);
    };

    const handleSecciones = (event) => {
        const idCompleto = event.target.id;
        const id = idCompleto.replace("seccion", "");
        let aux1 = seccionesSeleccionadas;

        if(event.target.checked){
            //Hay que agregar la seccion
            aux1.push(id);
            setSeccionesSeleccionadas(aux1);
        }else{
            //Hay que eliminar la seccion
            for(var i = 0; i < aux1.length; i++){ 
                if(aux1[i] === id){ 
                    aux1.splice(i, 1); 
                }
            }

            setSeccionesSeleccionadas(aux1);
        }
    };

    const handleOsMarca = (event) => {
        const idCompleto = event.target.id;
        let aux1 = osMarcasSeleccionadas;

        if(event.target.checked){
            //Hay que agregar la seccion
            aux1.push(idCompleto);
            setOsMarcasSeleccionadas(aux1);
        }else{
            //Hay que eliminar la seccion
            for(var i = 0; i < aux1.length; i++){ 
                if(aux1[i] === idCompleto){ 
                    aux1.splice(i, 1); 
                }
            }

            setOsMarcasSeleccionadas(aux1);
        }
    };

    useEffect(() => {
        document.title = "Unit - Notificaciones";
        getSecciones();
    }, []);

    const getSecciones = async function(){
        const token = localStorage.getItem("user-token");
        let request = await get("api/secciones-dptos/", token);
        const response = await request.json()
        setSecciones(response.data);
    }

    const crear = async function(){
        if(verifyInput()){
            let notification = {
                title: title,
                body: body,
                seccionIds: seccionesSeleccionadas,
                osMarca: osMarcasSeleccionadas
            };
    
            const token = localStorage.getItem("user-token");
            let request = await post("api/usuarios/notifications/", token, notification);
            const response = await request.json()
        
            if(request.status === 200){
                alert("Notificación enviada correctamente");
                window.location.href = "/notificaciones";
            }else{
                alert("Error al enviar la notificación " + response.data + " " + response.message);
            }
        }else{
            alert("Faltan campos obligatorios");
        }   
    }

    const verifyInput = function(){
        if(title.length > 0){
            if(body.length > 0){
                return true;
            }
        }
        return false;
    }

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1>Notificaciones</h1>
                    </Col>
                    <Col style={{textAlign: "right"}} className="mt-2">
                        <Link to={"/notificaciones/log"}>
                            <Button variant="success"><FontAwesomeIcon icon={faFile} style={{marginRight: 5}} />Ver notificaciones</Button>
                        </Link>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                    
                        <Form>
                            <Form.Group className="mb-2" >
                                <Form.Text className="text-muted">Titulo </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control  type="text" onChange = {(event) => handleTitle(event)} id="" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Cuerpo </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <Form.Control  type="text" onChange = {(event) => handleBody(event)} id="" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Text className="text-muted">Secciones </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <div className="mb-1"></div>
                                {secciones.map((s) => (
                                    <Form.Check id={"seccion" + s.id} onChange = {(event) => handleSecciones(event)} type="checkbox" label={s.agrupacion + " - " + s.nombre}/>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-5" >
                                <Form.Text className="text-muted">Sistema Operativo </Form.Text>
                                <Form.Text className="text-danger">Obligatorio</Form.Text>
                                <div className="mb-1"></div>
                                {osMarcas.map((os) => (
                                    <Form.Check id={os} onChange = {(event) => handleOsMarca(event)} type="checkbox" label={os}/>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-5" >
                                <Button variant="success" href={"#"} onClick={crear}>Enviar notificación</Button>{' '}
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}