import React, { useEffect, useState } from 'react'
import NavBarGeneral from '../navbar/NavBar'
import { getUserRole, getUserToken, validarUsuario } from '../login/UserController';
import { obtenerEjes, obtenerNovedades, obtenerUnidades, obtenerUnidadesPorEje, buscarUnidad, obtenerSecciones, obtenerMyData } from '../../controlador/index.controller';
import { Carousel, Col, Row, Container, Button, Card, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay, faPause, faMusic, faSearch, faFilter, faSadCry } from '@fortawesome/free-solid-svg-icons';
import NotFound from '../generalComponents/NotFound';
import Loading from '../generalComponents/Loading';
import Footer from '../generalComponents/Footer';

function Busqueda() {

    const rutaArchivos = "https://unit.fasta.org/data";
    useEffect(() => {
        document.title = "Inicio";
        validarUsuario();
        setDefaultValue(getParamsChain());
        const myArray = getParamsSeccion().split(',');
        setSelectedIds(myArray)
        setEje(getParamsEje());
        searchUnits(getParamsChain(), getParamsSeccion());
    }, []);
    const [eje, setEje] = useState(null)
    const [selectedIds, setSelectedIds] = useState(null)

    const [defaultValue, setDefaultValue] = useState(null);

    const getParamsChain = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('ch')) {
            return params.get('ch');
        } else {
            return -1;
        }
    }
    const getParamsSeccion = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('sc')) {
            return params.get('sc');
        } else {
            return "";
        }
    }
    const getParamsEje = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('j')) {
            return params.get('j');
        } else {
            return null;
        }
    }

    const imgSecciones = [
        { id: 1, src: '/img/Caperucitas.png' },
        { id: 2, src: null, img: 1 },
        { id: 3, src: '/img/Herederas.png' },
        { id: 4, src: '/img/Adalides.png' },
        { id: 5, src: null, img: 4 },
        { id: 6, src: '/img/Escuderos.png' },
        { id: 7, src: null, img: 6 },
        { id: 8, src: '/img/Templarios.png' },
        { id: 9, src: null, img: 8 },
    ]

    const imgs = [
        "https://concepto.de/wp-content/uploads/2015/03/paisaje-800x409.jpg",
        "https://cdn.pixabay.com/photo/2018/02/03/15/40/landscape-3127859_640.jpg",
        "https://s1.significados.com/foto/paisaje-og.jpg"
    ]

    const [unidades, setUnidades] = useState([]);
    const [unidadesCargadas, setUnidadesCargadas] = useState(false);

    const searchUnits = async (chain, secciones) => {
        const secci = secciones ? secciones.split(',') : [1, 2, 3, 4, 5, 6, 7];
        const body = {
            chain: chain,
            eje: getParamsEje(),
            secciones: secci,
        }
        if (chain.length >= 3) {
            setUnidadesCargadas(false);
            const uni = await buscarUnidad(getUserToken(), body);
            console.log(uni)
            setUnidadesCargadas(true)

            let arr = uni;

            arr.map((t, j) => {
                let arrStateImg = []
                t.unidades_secciones.map((r, i) => {
                    let id = r.seccionId;
                    if (imgSecciones[r.seccionId - 1].src == null) {
                        id = imgSecciones[r.seccionId - 1].img;
                    }
                    if (!arrStateImg.includes(id)) {
                        r.img = imgSecciones[id - 1].src;
                        arrStateImg.push(id);
                    } else {
                        if (id != r.seccionId) {
                            r.img = null;
                        }
                    }
                })
            })
            setUnidades(arr)
        }
    };

    //obtenerSecciones
    const handleSearchChange = async () => {
        const chain = document.getElementById(`textSearch`).value;
        window.location.href = getSearchUrl(chain);
    };

    const getSearchUrl = (chain) => {
        //ch=chain, sc=secciones, j=eje
        const arraySecciones = selectedIds.join(',');
        if (eje != "") {
            return `/busqueda?ch=${chain}&sc=${arraySecciones}&j=${eje}`
        }
        return `/busqueda?ch=${chain}&sc=${arraySecciones}`
    }
    /*     useEffect(() => {
            if (seccionesBusquedaIds.length > 0) {
                if (defaultValue != "") {
                    searchUnits(getParamsChain())
                } else {
                    handleSearchChange();
                }
            }
        }, [seccionesBusquedaIds]); */

    return (
        <>
            <div className='content ps-0 pe-0 '>
                <div className='d-none d-sm-block'>

                    {defaultValue && selectedIds != null && <NavBarGeneral defaultValue={defaultValue} seccionesBusq={selectedIds} />}
                    <div className='ps-5 pe-5 pt-3' style={{minHeight:'90vh'}}>
                        {/* <div className="gradientBackTransparent" style={{marginTop:'70px'}}/> */}
                        {/*                         <Row className="mb-2">
                            <SearchBar />
                        </Row> */}

                        {unidades && unidades.length > 0 && <Row className='p-0'>
                            <p style={{ textAlign: 'center' }}>{unidades && unidades.length} unidades encontradas</p>
                        </Row>}
                        <Container>
                            <Row className='mt-2'>
                                {unidadesCargadas ?
                                    unidades != null && unidades.length > 0 ? unidades.map((r, i) => (
                                        <Col md={6} className='mb-3'>
                                            <div className=''>
                                                <Link to={`unidad?id=${r.id}`}><Row className='br-15 boxShadow' style={{ maxHeight: '180px', overflow: 'hidden' }}>
                                                    <Col md={6} className='ps-3 pt-3 pb-3 flex' style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <div className='fs-14 ps-2 pe-2 gradientBack fitContentWidth' style={{ borderRadius: '10px', color: 'white' }} >
                                                                {r.unidades_ejes[0].eje.nombre}
                                                            </div>
                                                            <p className='fw-600 fs-18 mt-2' style={{ color: 'black' }}>{r.nombre}</p>
                                                        </div>
                                                        <p className='mb-0 fs-14 grey'>{`${"#" + r.etiquetas.split("; ").join(" #")}`}</p>
                                                    </Col>
                                                    <Col md={6} className='pe-0' style={{ height: '180px', overflow: 'hidden', position: 'relative' }}>
                                                        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${rutaArchivos}${r.imagen.uri}`} alt={r.nombre} />

                                                        <div style={{ position: 'absolute', bottom: '5px', right: '5px', backgroundColor: '#ffffffed', columnGap: '4px' }} className='p-1 br-15 flex a-center'>
                                                            {r.unidades_secciones.map((s, i) => s.img != null && (
                                                                <img style={{ height: '20px' }} src={s.img} />
                                                            ))}
                                                        </div>
                                                    </Col>
                                                </Row></Link>
                                            </div>
                                        </Col>
                                    )) :
                                        <div className='flex j-center a-center mt-3'>
                                            <NotFound text={"No se han encontrado unidades"} iconFa={faSadCry} />
                                        </div>

                                    :
                                    <div className='flex j-center a-center mt-3'>
                                        <Loading />
                                    </div>
                                }
                            </Row>
                        </Container>
                    </div>
                    <Footer/>
                </div>

                <div className='d-block d-sm-none' style={{ height: '100vh' }}>
                    <div style={{ height: '95vh', width: '95vw' }} className="p-3 flex a-center j-center">
                        <Card className='flex j-center gradientBack p-3'>
                            <img style={{ height: '70px' }} src="https://unit.fasta.org/img/logo.png" />

                            <p style={{ textAlign: 'center' }}>Instalate la app</p>

                            <a style={{ textAlign: 'center' }} href="https://play.google.com/store/apps/details?id=com.secdevs.fasta.unit&pcampaignid=web_share" target="blank"><Button variant="light">
                                Google PlayStore
                            </Button></a>

                            <a style={{ textAlign: 'center' }}><Button variant="light" className='mt-3'>
                                AppStore
                            </Button></a>
                        </Card>
                    </div>
                </div>

                {/*  <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Filtrar unidades</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Seleccioná las secciones por las que quieras filtrar las unidades buscadas</p>
                        {secciones && secciones.map((r, i) => (
                            <Form.Check
                                type="checkbox"
                                id={r.id}
                                label={r.nombre}
                                onChange={(e) => handleCheckChange(e, r.id)}
                                checked={selectedIds.includes(r.id)}
                            />
                        ))}
                        {secciones && secciones.length == selectedIds.length ?
                            <p style={{ textAlign: 'center', color: "#46F0AF" }} className='mt-2 mb-2 pointer' onClick={() => handleSelectAll(false)}>DESELECCIONAR TODO</p>

                            : <p style={{ textAlign: 'center', color: "#46F0AF" }} className='mt-2 mb-2 pointer' onClick={() => handleSelectAll(true)}>SELECCIONAR TODO</p>

                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button
                            variant="danger"
                            onClick={handleConfirm}
                        >
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal> */}

            </div >


        </>
    )
}

export default Busqueda;