import React from "react";
// eslint-disable-next-line
import {BrowserRouter as Router, Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faSignOutAlt, faUser, faNewspaper, faBell, faCog} from '@fortawesome/free-solid-svg-icons'
import '../../../css/navbar.css';


export default function NavBar(){

    const btnUnidades = function(){
        if(window.location.pathname.includes("/unidades")){
            return (<button className="botonPadre botonSeleccionado mt-5"><FontAwesomeIcon icon={faFileAlt} style={{marginRight: 10, marginLeft: 10}} />Unidades</button>);
        }else{
            return (<button className="botonPadre mt-5"><FontAwesomeIcon icon={faFileAlt} style={{marginRight: 10, marginLeft: 10}} />Unidades</button>);
        }
    }

    const btnUsuarios = function(){
        if(window.location.pathname.includes("/usuarios")){
            return (<button className="botonPadre botonSeleccionado mt-2"><FontAwesomeIcon icon={faUser} style={{marginRight: 10, marginLeft: 10}} />Usuarios</button>);
        }else{
            return (<button className="botonPadre mt-2"><FontAwesomeIcon icon={faUser} style={{marginRight: 10, marginLeft: 10}} />Usuarios</button>);
        }
    }

    const btnNovedades = function(){
        if(window.location.pathname.includes("/novedades")){
            return (<button className="botonPadre botonSeleccionado mt-2"><FontAwesomeIcon icon={faNewspaper} style={{marginRight: 8, marginLeft: 8}} />Novedades</button>);
        }else{
            return (<button className="botonPadre mt-2"><FontAwesomeIcon icon={faNewspaper} style={{marginRight: 8, marginLeft: 8}} />Novedades</button>);
        }
    }

    const btnNotificaciones = function(){
        if(window.location.pathname.includes("/notificaciones")){
            return (<button className="botonPadre botonSeleccionado mt-2"><FontAwesomeIcon icon={faBell} style={{marginRight: 8, marginLeft: 8}} />Notificaciones</button>);
        }else{
            return (<button className="botonPadre mt-2"><FontAwesomeIcon icon={faBell} style={{marginRight: 8, marginLeft: 8}} />Notificaciones</button>);
        }
    }

    const btnSettings = function(){
        if(window.location.pathname.includes("/configuracion")){
            return (<button className="botonPadre botonSeleccionado mt-2"><FontAwesomeIcon icon={faCog} style={{marginRight: 8, marginLeft: 8}} />Configuración</button>);
        }else{
            return (<button className="botonPadre mt-2"><FontAwesomeIcon icon={faCog} style={{marginRight: 8, marginLeft: 8}} />Configuración</button>);
        }
    }

    const logout = function(){
        localStorage.removeItem("user-token");
        localStorage.removeItem("user-nombre");
        localStorage.removeItem("user-apellido");
        localStorage.removeItem("user-id");
        window.location.href = "/";
    }

    return (
        <div>
            <img className="w-100 mt-3" style={{marginLeft: -12}} src="https://unit.fasta.org/img/logo.png" alt="Unit"/>
            <Link to={`/unidades/`}>
                {btnUnidades()}
            </Link>

            <Link to={`/usuarios/`}>
                {btnUsuarios()}
            </Link>

            <Link to={`/novedades/`}>
                {btnNovedades()}
            </Link>

            <Link to={`/notificaciones/`}>
                {btnNotificaciones()}
            </Link>

            <Link to={`/configuracion/`}>
                {btnSettings()}
            </Link>

            <Link to="/"><button className="botonSalir" href="#" ><FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: 10, marginLeft: 10}} />Volver a inicio</button>
        </Link></div>
    )
}