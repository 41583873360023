import React, { useEffect, useRef, useState } from 'react';
import NavBarGeneral from '../navbar/NavBar';
import { guardarUnidad, obtenerUnidad, obtenerUnidadesGuardadas } from '../../controlador/index.controller';
import { getUserId, getUserToken } from '../login/UserController';
import { Alert, Button, Modal } from 'react-bootstrap';
import CustomAudioPlayer from '../generalComponents/Audio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilm, faShare } from '@fortawesome/free-solid-svg-icons';
import Loading from '../generalComponents/Loading';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';

function Unidad() {
  const rutaArchivos = "https://unit.fasta.org/data";
  const rutaVideos = "https://www.youtube.com/watch?v=";

  const [unidad, setUnidad] = useState(null);
  const [ejes, setEjes] = useState(null)
  const [isUnitLoaded, setUnitLoaded] = useState(false);
  const [ejeLength, setEJeLength] = useState({});
  const [lengthSmallEjes, setLengthSmallEjes] = useState()
  
  const getUnidad = async (id) => {
    setUnitLoaded(false);
    const res = await obtenerUnidad(getUserToken(), id);
    console.log(res);
    setUnitLoaded(true);
    
    let cont = 0;
    let a = res.unidad_contenido; //guarda la  posicion de cada "pestaña" dentro de cada eje.
    let b =[[], [], [], [], []]; //Guarda cuantos nodos hijo tiene cada pestaña.
    
    //Espiritualidad
    if (a.espiritualidadDominicanaId != null) {
      b[1][cont]=0;
      cont++;
      if (a.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length > 0) {
        a.espiritualidad_dominicana.imgs_espiritualidad_dominicanas[0].pos = cont;
       b[1][cont]=a.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length;
        cont+=a.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length;
      }
      if (a.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas.length > 0) {
        b[1][cont]=0;
        a.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas[0].pos = cont;
        cont+=a.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas.length;
      }
    }


    ejeLength.espi = cont-1;
    
    //Elementos simbolicos
    cont = 0;
    if (a.elementosSimbolicosMarchaId != null) {
      a.elementos_simbolicos_marcha.pos = cont;
      b[2][cont]=0;
      cont++;
    }
    if (a.elementos_simbolicos_imagenes.length > 0) {
      a.elementos_simbolicos_imagenes[0].pos = cont;
      b[2][cont] = a.elementos_simbolicos_imagenes.length;
      cont++;
    }
    if (a.elementosSimbolicosArteId != null) {
      b[2][cont]=a.elementos_simbolicos_arte.imgs_elementos_simbolicos_artes.length+1;
      a.elementos_simbolicos_arte.pos = cont;
      cont++;
    }
    if (a.elementosSimbolicosCuentoId != null) {
      b[2][cont]=0;
      a.elementos_simbolicos_cuento.pos = cont;
      cont++;
    }
    if (a.elementosSimbolicosPoesiaId != null) {
      b[2][cont]=0;
      a.elementos_simbolicos_poesia.pos = cont;
      cont++;
    }
    ejeLength.simbolico = cont - 1;

    //Magisterio
    cont = 0;
    if (a.magisterioConsignaId != null) {
      b[3][cont]=0;
      a.magisterio_consigna.pos = cont;
      cont++;
    }
    if (a.magisterioReflexionId != null) {
      b[3][cont]=0;
      a.magisterio_reflexion.pos = cont;
      cont++;
    }
    if (a.magisterioProfundizacionId != null) {
      b[3][cont]=0;
      a.magisterio_profundizacion.pos = cont;
      cont++;
    }
    if (a.magisterioPoesiaId != null) {
      b[3][cont]=0;
      a.magisterio_poesia.pos = cont;
      cont++;
    }
    ejeLength.magisterio = cont - 1;

    //Recursos
    cont = 0;
    if (a.recursosInfografiaId != null && a.recursos_infografia.imgs_recursos_infografia.length > 0) {
      a.recursos_infografia.pos = cont;
      b[4][cont] = a.recursos_infografia.imgs_recursos_infografia.length;
      cont++;
    }
    if (a.recursosVideoId != null) {
      b[4][cont]=0;
      a.recursos_video.pos = cont;
      cont++;
    }
    if (a.recursosPeliculaId != null) {
      b[4][cont]=0;
      a.recursos_pelicula.pos = cont;
      cont++;
    }
    if (a.recursos_cancions.length > 0) {
      a.recursos_cancions[0].pos = cont;
      b[4][cont] = a.recursos_cancions.length;
      cont++;
    }
    if (a.recursosActividadId != null) {
      b[4][cont]=0;
      a.recursos_actividad.pos = cont;
      cont++;
    }
    if (a.recursosSantoId != null) {
      b[4][cont]=a.recursos_santo.imgs_recursos_santos.length +1;
      a.recursos_santo.pos = cont;
      cont++;
    }
    if (a.recursosOracionId != null) {
      b[4][cont]=0;
      a.recursos_oracion.pos = cont;
      cont++;
    }
    if (a.recursosSalmoId != null) {
      b[4][cont]=0;
      a.recursos_salmo.pos = cont;
      cont++;
    }

    ejeLength.recursos = cont - 1;

    let r = res;
    r.unidad_contenido = a;
    setUnidad(r);
    setUnitLoaded(true);
    setLengthSmallEjes(b);
  }

  useEffect(() => {
    getUnidad(getParams());
    getUnidadesGuardadas();
    document.getElementById('mainContainer').focus();
  }, []);


  const getParams = function () {
    const params = new URLSearchParams(window.location.search);
    if (params.has('id')) {
      return params.get('id');
    } else {
      return -1;
    }
  }

  const [position, setPosition] = useState(0);

  const handleScroll = (direction) => { //big container
    setSlideSubWanted(0)
    switch (direction) {
      case 'down':
        setActualContainerNumber(3)
        setSubPosition(0)
        setPosition(1);
        setActualContainer('middleContainer')
        break;
      case 'up':
        setActualContainerNumber(1)
        setSubPosition(0)
        setPosition(2);
        setActualContainer('middleContainer')
        break;
      case 'right':
        setActualContainerNumber(2)
        setSubPosition(0)
        setPosition(3);
        setActualContainer('middleContainer')
        break;
      case 'left':
        setActualContainerNumber(4)
        setSubPosition(0)
        setPosition(4);
        setActualContainer('middleContainer')
        break;
      case 'initial':
        setActualContainer("bigContainer")
        setActualContainerNumber(0)
        setPosition(0);
        break;
      default:
        break;
    }

  };


  const [subPosition, setSubPosition] = useState(0);
  const [slideWanted, setSlideWanted] = useState(0);

  const handleScrollSub = (direction, ind) => { //middle container
    setSlideWanted(ind);
    setSlideSubWanted(0)
    setActualContainer('middleContainer')
    switch (direction) {
      case 'down':
        setSubSubPosition(9)
        setSubPosition(5); // Mueve hacia abajo
        break;
      case 'up':
        setSubSubPosition(9)
        setSubPosition(6); // Mueve hacia arriba
        break;
      case 'right':
        setSubSubPosition(9)
        setSubPosition(7); // Mueve hacia la derecha
        break;
      case 'left':
        setSubSubPosition(9)
        setSubPosition(8); // Mueve hacia la izquierda
        break;
      case 'initial':
        setActualContainerNumber(0)
        setSubSubPosition(9)
        setSubPosition(9);
        setActualContainer('bigContainer')
        break;
      default:
        break;
    }
  };

  const [subSubPosition, setSubSubPosition] = useState(0);
  const [slideSubWanted, setSlideSubWanted] = useState(0);

  const handleScrollSubSub = (direction, ind) => { //small container
    setSlideSubWanted(ind);
    if (ind == 0) {
      setActualContainer('middleContainer')
    } else {
      setActualContainer('smallContainer')
    }
    switch (direction) {
      case 'down':
        setSubSubPosition(5); // Mueve hacia abajo
        break;
      case 'up':
        setSubSubPosition(6); // Mueve hacia arriba
        break;
      case 'right':
        setSubSubPosition(7); // Mueve hacia la derecha
        break;
      case 'left':
        setSubSubPosition(8); // Mueve hacia la izquierda
        break;
      case 'initial':
        setActualContainerNumber(0);
        setSubSubPosition(0);
        setActualContainer('bigContainer')
        break;
      default:
        break;
    }
  };

  const [actualContainer, setActualContainer] = useState('bigContainer'); //para guardar en qué tipo de container estoy (small, middle, big).
  const [actualContainerNumber, setActualContainerNumber] = useState(0) //1=amarillo, 2=celeste, 3=rojo, 4=verde

  const handleMoveKeyboard = (event) => {
    let pos = 0; //posición actual del slide en el que estoy.
    let EjeSize = actualContainerNumber == 2 ? ejeLength.simbolico : actualContainerNumber == 3 ? ejeLength.magisterio : actualContainerNumber == 4 ? ejeLength.recursos : ejeLength.espi;
    let funct; //función handleScroll correspondiente al contianer en el que estoy
    let subEjeSize = lengthSmallEjes[actualContainerNumber][Math.abs(slideWanted)]; 
    let smallEje = false; 

    if (actualContainer.includes('big')) {
      funct = (a, b) => handleScroll(a);
    } else if (actualContainer.includes('middle')) {
      funct = (a, b) => handleScrollSub(a, b);
      if (slideWanted)
        pos = slideWanted;
    } else if (actualContainer.includes('small')) {
      funct = (a, b) => handleScrollSubSub(a, b);
      pos = slideSubWanted;
      smallEje=true;
      subEjeSize = lengthSmallEjes[actualContainerNumber][Math.abs(slideWanted)];
    }

    switch (event.key) {
      case 'ArrowUp':
        if (pos == 0 && actualContainerNumber == 3) {
          handleScroll("initial")
          break;
        }
        if ((actualContainerNumber == 1 )) { 
            break;
        }else if (actualContainerNumber == 2 || actualContainerNumber == 4) {
          if(slideSubWanted!=0 && Math.abs(slideSubWanted+1)  < subEjeSize )
            handleScrollSubSub('up', slideSubWanted + 1);
          break;
        }
        funct("up", pos + 1)
        break;

      case 'ArrowDown':
        if (actualContainerNumber == 1) {
          handleScroll("initial")
          break;
        }
        if(smallEje && Math.abs(pos-1) > subEjeSize){
          break;
        }
        if (actualContainerNumber == 2 || actualContainerNumber == 4) {
          if(Math.abs(slideSubWanted -1)  < subEjeSize )
            handleScrollSubSub('down', slideSubWanted - 1);
          break;
        }
        if ((actualContainerNumber == 3 && Math.abs(pos - 1) <= EjeSize) || actualContainerNumber != 3) {
          funct("down", pos - 1)
        }
        break;

      case 'ArrowLeft':
        if(smallEje){
          if(actualContainerNumber==2 && slideWanted==0){
            handleScroll("initial")
          }else if(Math.abs(slideWanted-1) <= EjeSize){
            handleScrollSub("left", slideWanted-1)
          }
          break;
        }
        if (pos == 0 && actualContainerNumber == 2) {
          handleScroll("initial")
          break;
        }
        if(actualContainerNumber==3){
          break;
        }
        if ((actualContainerNumber == 4 && Math.abs(pos - 1) <= EjeSize)) {
          funct("left", pos - 1)
          break;
        }
        if(actualContainerNumber == 1){
          if(slideWanted-1 >=0){
            funct("left", slideWanted - 1)
          }
          break;
        }
        if(actualContainerNumber==0){
          handleScroll("left")
          break;
        }
        if(actualContainerNumber != 4){
          funct("left", pos - 1)
        }
        break;

      case 'ArrowRight':
        if(smallEje){
          if(actualContainerNumber==4 && slideWanted==0){
            handleScroll("initial")
          }else if(Math.abs(slideWanted+1) <=EjeSize){
            
            handleScrollSub("right", slideWanted+1)
          }
          break;
        }
        if(actualContainerNumber==3){
          break;
        }
        if (pos == 0 && actualContainerNumber == 4) {
          handleScroll("initial")
          break;
        }
        if (((actualContainerNumber == 2 || actualContainerNumber==1) && Math.abs(pos + 1) <= EjeSize)) {
          funct("right", pos + 1)
          break;
        }
        if(actualContainerNumber==0){
          handleScroll("right")
          break;
        }
        if(actualContainerNumber!=2 && actualContainerNumber!=1){
          funct("right", pos + 1)
        }
        
      default:
        break;
    }
  };



  const [showAlert, setShowAlert] = useState(false);
  const compartir = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 1500);
      })
      .catch(err => {
        console.error("Error al copiar la URL: ", err);
      });
  }

  const downloadImage = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'imagen_unit.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const postGuardarUnidad = async function () {
    let body = {
      unidadId: getParams(),
      usuarioId: getUserId(),
      favorito: !isSaved
    };

    const token = localStorage.getItem("user-token");
    let res = await guardarUnidad(token, body);
    setIsSaved(!isSaved)
  };

  const [isSaved, setIsSaved] = useState(false);
  const getUnidadesGuardadas = async () => {
    const res = await obtenerUnidadesGuardadas(getUserToken(), getUserId());
    let m = res.map((r) => r.unidad);
    if (m.some((unidad) => unidad.id == getParams())) {
      setIsSaved(true);
    } else {
      setIsSaved(false)
    }
  }

  const [showModalActions, setShowModalActions] = useState(false);
  const handleCloseActions = () => setShowModalActions(false);
  const handleShowActions = () => setShowModalActions(true);

  return (
    <div id='mainContainer' tabIndex="0" onKeyDown={handleMoveKeyboard} className="content ps-0 pe-0" style={{ overflowX: 'hidden' }}>
      <NavBarGeneral />
      <div style={{ width: '100vw', height: 'calc(100vh - 70px)', overflow: 'hidden' }}>
        {unidad && isUnitLoaded ?
          <div className={`contentUnidad ${position === 0 ? 'initial' : ''} ${position === 1 ? 'down' : ''} ${position === 2 ? 'up' : ''} ${position === 3 ? 'right' : ''} ${position === 4 ? 'left' : ''}`}>

            {/* Principal */}
            <div style={{ width: '100vw' }} className='div1 flex a-center j-center unidadContainer'>
              {unidad != null &&
                <div className='flex a-center' style={{ flexDirection: 'column' }}>
                  <img src={`${rutaArchivos}${unidad.imagen.uri}`} style={{ height: '250px' }} className=' br-15' />
                  <p style={{ textAlign: 'center' }} className='fw-600 fs-26 mb-3 mt-3'>{unidad.nombre}</p>
                  <p style={{ textAlign: 'center', maxWidth:'75vw' }}>{unidad.descripcion}</p>
                  <div className='flex j-center a-center' style={{ columnGap: '15px' }}>
                    {isSaved ? <img style={{ height: '50px' }} src='/img/Guardar-seleccionado.png' className='pointer' onClick={postGuardarUnidad} />
                      : <img style={{ height: '50px' }} src='/img/Guardar.png' className='pointer' onClick={postGuardarUnidad} />
                    }<img style={{ height: '50px' }} src='/img/Bibliografía.png' className='pointer' onClick={handleShow} />
                    <img style={{ height: '50px' }} src='/img/Compartir.png' onClick={() => compartir(`https://unit.fasta.org/unidad?id=${getParams()}`)} className='pointer' />
                    <img style={{ height: '50px' }} src='/img/Agregar.png' onClick={handleShowActions} className='pointer' />
                  </div>
                </div>}
              <div onClick={() => handleScroll('up')} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} >
                <img style={{ width: '500px', marginTop: '-108%' }} src='/img/flecha_amarilla.png' className='pointer' />
                <img src="/img/Desliza.png" style={{ position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} className='pointer' />
              </div>
              <div onClick={() => handleScroll('down')} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                <img style={{ width: '500px', marginBottom: '-108%' }} src='/img/flecha_roja.png' className='pointer' />
                <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />
              </div>
              <div onClick={() => handleScroll('left')} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)', overflow: 'hidden' }} >
                <img style={{ height: '600px', marginLeft: '-91%' }} src='/img/flecha_verde.png' className='pointer' />
                <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '40px', zIndex: '1200', left: '0px', top: '46.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-25px' }} />
              </div>
              <div onClick={() => handleScroll('right')} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                <img style={{ height: '600px', marginLeft: '91%' }} src='/img/flecha_azul.png' className='pointer' />
                <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '40px', zIndex: '1200', right: '0px', top: '46.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-25px' }} />
              </div>
            </div>

            {/* Espiritualidad - ama */}
            <div className="div3 p-3" style={{ backgroundColor: '#FFE335' }}>
              <div className={`contentUnidad ${subPosition === 7 ? 'right' : ''} ${subPosition === 8 ? 'left' : ''}`} style={{
                height: '100%',
                width: '100%',
                ...(subPosition === 7 ? { transform: `translateX(calc(-100vw * ${slideWanted}))` } : {}),
                ...(subPosition === 8 ? { transform: `translateX(calc(-100vw * ${slideWanted}))` } : {})
              }}>
                <div style={{ backgroundColor: '#ffffc2', width: '100%', height: '100%' }} className={` br-15 p-2`}  >
                  <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Espiritualidad</p>
                  {unidad && unidad.unidad_contenido.espiritualidadDominicanaId != null && <>
                    <p className='fw-600' style={{ textAlign: 'center', color: '#FFE335' }}>ESPIRITUALIDAD DOMINICANA</p>
                    <div style={{ width: '100%', height:'70%' }} className='flex a-center j-center p-5 pt-2 pb-2  pt-2 pb-2'>
                      <div style={{ maxHeight: '55vh', overflowY: 'auto', textAlign: 'center' }} className='pe-1'>
                        {unidad.unidad_contenido.espiritualidad_dominicana.texto.split('\n').map((line, index) => (<>
                          {line}
                          <br /></>))}
                      </div>
                    </div>
                    <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                      <img src='/img/Compartir_ama.png' className='he-50 pointer' onClick={() => compartir(`${unidad.unidad_contenido.espiritualidad_dominicana.texto}`)} />
                    </div>
                  </>}

                  {unidad && unidad.unidad_contenido.espiritualidad_dominicana && (unidad.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length>0 || unidad.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas.length > 0 )&&
                    <div onClick={() => handleScrollSub('right', 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                      <img style={{ height: '400px', marginLeft: '108%', rotate: '90deg' }} src='/img/flecha_amarilla.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '46.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                    </div>
                  }

                </div>

                {/* imagenes */}
                {unidad && unidad.unidad_contenido.espiritualidadDominicanaId != null && unidad.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas != null && unidad.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length > 0 &&
                  unidad.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.map((r, i) => (
                    <div style={{ backgroundColor: '#ffffc2', width: '100%', height: '100%', left: `calc(100vw * ${i + 1})` }} key={i} className='div4 p-2' >
                      <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Espiritualidad</p>
                      {unidad && unidad.unidad_contenido.espiritualidadDominicanaId != null && <>
                        <p className='fw-600' style={{ textAlign: 'center', color: '#FFE335' }}>ESPIRITUALIDAD DOMINICANA</p>
                        <div style={{ height: '50vh', overflowY: 'auto', width: '100%' }} className='flex a-center j-center'>
                          <img src={`${rutaArchivos}${r.imagen.uri}`} style={{ height: '50vh' }} className='br-15' />
                        </div>
                      </>}
                      <div onClick={() => handleScrollSub('left', -i)} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                        <img style={{ height: '400px', marginLeft: '-350%', rotate: '270deg' }} src='/img/flecha_amarilla.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '46.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />

                      </div>
                      {(i + 1 < unidad.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length || unidad.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas.length > 0) &&
                        <div onClick={() => handleScrollSub('right', i + 2)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                          <img style={{ height: '400px', marginLeft: '108%', rotate: '90deg' }} src='/img/flecha_amarilla.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '46.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                        </div>}
                    </div>
                  ))

                }

                {/* Links */}
                {unidad && unidad.unidad_contenido.espiritualidadDominicanaId != null && unidad.unidad_contenido.espiritualidad_dominicana && unidad.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas.length > 0 &&
                  unidad.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas.map((r, i) => (
                    <div style={{ backgroundColor: '#ffffc2', width: '100%', height: '100%', left: `calc(100vw * ${unidad.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length + i + 1})` }} key={i} className='div4 p-2' >
                      <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Espiritualidad</p>
                      <p className='fw-600' style={{ textAlign: 'center', color: '#FFE335' }}>ESPIRITUALIDAD DOMINICANA</p>
                      <div style={{ height: '55vh', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center j-center'>
                        <p>{r.texto}</p>
                        <a href={r.enlace} target='blank'><button style={{ backgroundColor: '#FFE335', border: 'none', borderRadius: '5px' }} className='p-2 pe-4 ps-4'>
                          ABRIR PÁGINA
                        </button></a>
                      </div>
                      <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length + i))} style={{ width: '100px', position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                        <img style={{ height: '400px', rotate: '270deg', marginLeft: '-350%' }} src='/img/flecha_amarilla.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '46.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />
                      </div>
                      {i + 1 < unidad.unidad_contenido.espiritualidad_dominicana.enlaces_espiritualidad_dominicanas.length && <div onClick={() => handleScrollSub('right', unidad.unidad_contenido.espiritualidad_dominicana.imgs_espiritualidad_dominicanas.length + i + 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                        <img style={{ height: '400px', marginLeft: '103%', rotate: '90deg' }} src='/img/flecha_amarilla.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '46.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                      </div>}
                    </div>
                  ))}
              </div>

              <div onClick={() => handleScroll('initial')} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} className='pointer'>
                <img style={{ width: '500px', marginBottom: '-108%', rotate: '180deg' }} src='/img/flecha_amarilla.png' />
                <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

              </div>
            </div>

            {/* Reflexión - rojo */}
            <div className="div2 p-3" style={{ backgroundColor: '#FF4F54' }}>
              <div className={`contentUnidad ${subPosition === 5 ? 'down' : ''} ${subPosition === 6 ? 'up' : ''} ${subPosition === 7 ? 'right' : ''} ${subPosition === 8 ? 'left' : ''}`} style={{
                height: '100%',
                width: '100%',
                ...(subPosition == 5 ? { transform: `translateY(calc(-100vh * ${Math.abs(slideWanted)}))` } : {}),
                ...(subPosition == 6 ? { transform: `translateY(calc(-100vh * ${Math.abs(slideWanted)}))` } : {}),
                ...(subPosition == 7 ? { transform: `translateX(calc(100vw * ${Math.abs(slideWanted)}))` } : {}),
                ...(subPosition == 8 ? { transform: `translateX(calc(100vw * ${slideWanted}))` } : {})
              }}>

                {/*consigna*/}
                {unidad && unidad.unidad_contenido.magisterioConsignaId != null &&
                  <div style={{ backgroundColor: '#ffe0e1', width: '100%', height: '100%' }} className='br-15 p-2 pt-5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Magisterio del Fundador</p>

                    <p className='fw-600' style={{ textAlign: 'center', color: '#FF4F54' }}>CONSIGNA</p>
                    <div style={{ height: '55vh', width: '100%', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  j-center'>
                      <p style={{ textAlign: 'center' }}>{unidad.unidad_contenido.magisterio_consigna.consigna}</p>
                      {unidad.unidad_contenido.magisterio_consigna.explicacion != "" &&
                        <><div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                          <div style={{ textAlign: 'center', overflowY: 'auto' }}>
                            {unidad.unidad_contenido.magisterio_consigna.explicacion}
                          </div></>
                      }
                    </div>
                    <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                      <img src='/img/Compartir_rojo.png' className='he-50 pointer' onClick={() => compartir(`${unidad.unidad_contenido.magisterio_consigna.consigna} \n ${unidad.unidad_contenido.magisterio_consigna.explicacion}`)} />
                    </div>
                    <div onClick={() => handleScroll('initial')} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                      <img style={{ width: '500px', marginTop: '-110%', rotate: '180deg' }} src='/img/flecha_roja.png' />
                      <img src="/img/Desliza.png" style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} className='pointer' />

                    </div>
                    {unidad && unidad.unidad_contenido.magisterio_consigna.pos < ejeLength.magisterio && <div onClick={() => handleScrollSub('down', -(unidad.unidad_contenido.magisterio_consigna.pos + 1))} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                      <img style={{ width: '500px', marginBottom: '-110%' }} src='/img/flecha_roja.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ marginBottom: '-8px', position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

                    </div>}
                  </div>
                }

                {/*Reflexión*/}
                {unidad && unidad.unidad_contenido.magisterioReflexionId != null &&
                  <div style={{ backgroundColor: '#ffe0e1', width: '100%', height: '100%', top: `calc(100vh * ${Math.abs(unidad.unidad_contenido.magisterio_reflexion.pos)})`, left: '0' }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Magisterio del Fundador</p>

                    <p className='fw-600' style={{ textAlign: 'center', color: '#FF4F54' }}>REFLEXIÓN</p>
                    <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  j-center'>
                      <p style={{ textAlign: 'center' }}>{unidad.unidad_contenido.magisterio_reflexion.texto}</p>
                    </div>
                    <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                      <img src='/img/Compartir_rojo.png' className='he-50 pointer' onClick={() => compartir(`${unidad.unidad_contenido.magisterio_reflexion.texto}`)} />
                    </div>

                    <div onClick={unidad.unidad_contenido.magisterio_reflexion.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('up', unidad.unidad_contenido.magisterio_reflexion.pos - 1)} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                      <img style={{ width: '500px', marginTop: '-110%', rotate: '180deg' }} src='/img/flecha_roja.png' />
                      <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                    </div>
                    {unidad && unidad.unidad_contenido.magisterio_reflexion.pos < ejeLength.magisterio && <div onClick={() => handleScrollSub('down', -(unidad.unidad_contenido.magisterio_reflexion.pos + 1))} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                      <img style={{ width: '500px', marginBottom: '-110%' }} src='/img/flecha_roja.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ marginBottom: '-8px', position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

                    </div>}
                  </div>}

                {/*Profundización*/}
                {unidad && unidad.unidad_contenido.magisterioProfundizacionId != null && <>
                  <div style={{ backgroundColor: '#ffe0e1', width: '100%', height: '100%', top: `calc(100vh * ${Math.abs(unidad.unidad_contenido.magisterio_profundizacion.pos)})`, left: '0' }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Magisterio del Fundador</p>

                    <p className='fw-600' style={{ textAlign: 'center', color: '#FF4F54' }}>PROFUNDIZACIÓN</p>
                    <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  j-center'>
                      <p style={{ textAlign: 'center' }}>{unidad.unidad_contenido.magisterio_profundizacion.texto}</p>
                      <a href={unidad.unidad_contenido.magisterio_profundizacion.enlace} target='blank'><button style={{ backgroundColor: '#FF4F54', border: 'none', borderRadius: '5px', color: 'white' }} className='p-2 pe-4 ps-4'>
                        ABRIR PÁGINA
                      </button></a></div>

                    <div onClick={unidad.unidad_contenido.magisterio_profundizacion.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('up', unidad.unidad_contenido.magisterio_profundizacion.pos - 1)} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                      <img style={{ width: '500px', marginTop: '-110%', rotate: '180deg' }} src='/img/flecha_roja.png' />
                      <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                    </div>
                    {unidad && unidad.unidad_contenido.magisterio_profundizacion.pos < ejeLength.magisterio && <div onClick={() => handleScrollSub('down', unidad.unidad_contenido.magisterio_profundizacion.pos + 1)} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                      <img style={{ width: '500px', marginBottom: '-110%' }} src='/img/flecha_roja.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ marginBottom: '-8px', position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

                    </div>}
                  </div>
                </>}

                {/*Poesía*/}
                {unidad && unidad.unidad_contenido.magisterioPoesiaId != null &&
                  <div style={{ backgroundColor: '#ffe0e1', width: '100%', height: '100%', top: `calc(100vh * ${Math.abs(unidad.unidad_contenido.magisterio_poesia.pos)})`, left: '0' }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Magisterio del Fundador</p>

                    <p className='fw-600' style={{ textAlign: 'center', color: '#FF4F54' }}>POESÍA</p>
                    <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  j-center'>
                      <p className='fs-18 fw-600'>{unidad.unidad_contenido.magisterio_poesia.titulo}</p>
                      <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                      <div style={{ textAlign: 'center', overflowY: 'auto' }}>{unidad.unidad_contenido.magisterio_poesia.texto.split('\n').map((line, index) => (<>
                        {line}
                        <br /></>))}</div>
                    </div>
                    <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                      <img src='/img/Compartir_rojo.png' className='he-50 pointer' onClick={() => compartir(`${unidad.unidad_contenido.magisterio_poesia.texto}`)} />
                    </div>

                    <div onClick={unidad.unidad_contenido.magisterio_poesia.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('up', unidad.unidad_contenido.magisterio_poesia.pos - 1)} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                      <img style={{ width: '500px', marginTop: '-110%', rotate: '180deg' }} src='/img/flecha_roja.png' />
                      <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                    </div>
                  </div>}
              </div>
            </div>

            {/*Signos y simbolos - azul */}
            <div className="div4 p-3" style={{ backgroundColor: '#01E6FD' }}>
              <div className={`contentUnidad ${subPosition === 5 ? 'down' : ''} ${subPosition === 6 ? 'up' : ''} ${subPosition === 7 ? 'right' : ''} ${subPosition === 8 ? 'left' : ''}`} style={{
                height: '100%',
                width: '100%',
                ...(subPosition === 5 ? { transform: `translateY(calc(-100vh * ${slideWanted}))` } : {}),
                ...(subPosition === 6 ? { transform: `translateY(calc(100vh * ${slideWanted}))` } : {}),
                ...(subPosition === 7 ? { transform: `translate(calc(-100vw * ${slideWanted}), calc(${subSubPosition == 6 ? '100vh' : '100vh'} * ${slideSubWanted}))` } : {}),
                ...(subPosition === 8 ? { transform: `translate(calc(-100vw * ${slideWanted}), calc(${subSubPosition == 6 ? '100vh' : '100vh'} * ${slideSubWanted}))` } : {}),
                ...(subPosition === 0 ? { transform: `translate(calc(0vw * ${slideWanted}), calc(${subSubPosition == 6 ? '100vh' : '100vh'} * ${slideSubWanted}))` } : {})
              }}>

                {/*Marcha*/}
                {unidad && unidad.unidad_contenido.elementosSimbolicosMarchaId != null &&
                  <div style={{ backgroundColor: 'rgb(195 250 255)', width: '100%', height: '100%' }} className='br-15 p-2 pt-5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Signos y símbolos</p>
                    {unidad && unidad.unidad_contenido.elementosSimbolicosMarchaId != null && <>
                      <p className='fw-600' style={{ textAlign: 'center', color: '#01E6FD' }}>MARCHA</p>
                      <div style={{ height: '55vh', width: '100%', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  j-center'>
                        <p className='fs-18 fw-600' style={{ textAlign: 'center' }}>{unidad.unidad_contenido.elementos_simbolicos_marcha.marcha.titulo}</p>
                        <div style={{ maxHeight: '30vh', overflow: 'auto' }}>{unidad.unidad_contenido.elementos_simbolicos_marcha.explicacion}</div>
                        <CustomAudioPlayer audioSrc={`${rutaArchivos}${unidad.unidad_contenido.elementos_simbolicos_marcha.marcha.audio}`} controlsColor={'#01E6FD'} progressBarColor={'#01E6FD'} title={unidad.unidad_contenido.elementos_simbolicos_marcha.marcha.titulo} lyrics={unidad.unidad_contenido.elementos_simbolicos_marcha.marcha.letra} colorString={"azul"} />
                      </div>
                    </>}
                    <div onClick={() => handleScroll('initial')} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                      <img style={{ height: '600px', marginLeft: '-94%', rotate: '180deg' }} src='/img/flecha_azul.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />

                    </div>
                    {unidad.unidad_contenido.elementos_simbolicos_marcha.pos < ejeLength.simbolico && <div onClick={() => handleScrollSub('right', 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                      <img style={{ height: '600px', marginLeft: '79%', rotate: '0deg' }} src='/img/flecha_azul.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                    </div>}
                  </div>
                }

                {/* Imágenes */}
                {unidad && unidad.unidad_contenido.elementos_simbolicos_imagenes.length > 0 &&
                  <div style={{ width: '100%' }}>
                    {unidad.unidad_contenido.elementos_simbolicos_imagenes.map((r, i) => (
                      <div style={{ backgroundColor: 'rgb(195 250 255)', width: '100%', height: '100%', top: `calc(100vh * ${i})`, left: `calc(100vw * ${unidad.unidad_contenido.elementos_simbolicos_imagenes[0].pos})` }} className='br-15 p-2 pt-5 div5'>
                        <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Signos y símbolos</p>
                        {unidad && unidad.unidad_contenido.magisterioReflexionId != null && <>
                          <p className='fw-600' style={{ textAlign: 'center', color: '#01E6FD' }}>REPRESENTACIONES</p>
                          <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                            <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{r.titulo}</p>
                            {r.imagen != null && <img src={`${rutaArchivos}${r.imagen}`} style={{ height: '50vh' }} className='br-15' />}<div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                            <div style={{ textAlign: 'center', overflowY: 'auto' }}>{r.texto}</div>
                          </div>
                        </>}
                        {i != 0 && <div onClick={() => handleScrollSubSub('top', -(i - 1))} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                          <img style={{ width: '500px', marginTop: '-132%', rotate: '270deg' }} src='/img/flecha_azul.png' />
                          <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                        </div>}
                        {i < unidad.unidad_contenido.elementos_simbolicos_imagenes.length - 1 && <div onClick={() => handleScrollSubSub('down', -(i + 1))} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                          <img style={{ width: '500px', marginBottom: '-132%', rotate: '90deg' }} src='/img/flecha_azul.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ marginBottom: '-8px', position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

                        </div>}
                        <div onClick={unidad.unidad_contenido.elementos_simbolicos_imagenes[0].pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('left', 0)} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                          <img style={{ height: '600px', marginLeft: '-94%', rotate: '180deg' }} src='/img/flecha_azul.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                        </div>

                        {unidad.unidad_contenido.elementos_simbolicos_imagenes[0].pos < ejeLength.simbolico && <div onClick={() => handleScrollSub('right', unidad.unidad_contenido.elementos_simbolicos_imagenes[0].pos + 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                          <img style={{ height: '600px', marginLeft: '79%', rotate: '0deg' }} src='/img/flecha_azul.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                        </div>}
                      </div>
                    ))
                    }

                  </div>}

                {/* Arte */}
                {unidad && unidad.unidad_contenido.elementosSimbolicosArteId &&
                  <div style={{ width: '100%' }}>
                    {unidad.unidad_contenido.elementos_simbolicos_arte.imgs_elementos_simbolicos_artes.map((r, i) => (
                      <div style={{ backgroundColor: 'rgb(195 250 255)', width: '100%', height: '100%', top: `calc(100vh * ${i})`, left: `calc(100vw * ${unidad.unidad_contenido.elementos_simbolicos_arte.pos})` }} className='br-15 p-2 pt-5 div5'>
                        <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Signos y símbolos</p>
                        {unidad && unidad.unidad_contenido.elementosSimbolicosArteId != null && <>
                          <p className='fw-600' style={{ textAlign: 'center', color: '#01E6FD' }}>OBRA DE ARTE</p>
                          <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                            {r.imagen != null && <img src={`${rutaArchivos}${r.imagen.uri}`} style={{ height: '50vh' }} className='br-15' />}
                            <div className="fs-18 fw-600" style={{ textAlign: 'center' }}>{unidad.unidad_contenido.elementos_simbolicos_arte.titulo}</div>
                            <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                            <div style={{ textAlign: 'center' }}>
                              {`${unidad.unidad_contenido.elementos_simbolicos_arte.autor} ${unidad.unidad_contenido.elementos_simbolicos_arte.fecha !== "" ? '(' + unidad.unidad_contenido.elementos_simbolicos_arte.fecha + ')' : ''}`} </div>
                          </div>
                        </>}
                        {i != 0 && <div onClick={() => handleScrollSubSub('top', -(i - 1))} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                          <img style={{ width: '500px', marginTop: '-132%', rotate: '270deg' }} src='/img/flecha_azul.png' />
                          <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                        </div>}
                        <div onClick={() => handleScrollSubSub('down', -(i + 1))} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                          <img style={{ width: '500px', marginBottom: '-132%', rotate: '90deg' }} src='/img/flecha_azul.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ marginBottom: '-8px', position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

                        </div>
                        <div onClick={unidad.unidad_contenido.elementos_simbolicos_arte.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('left', unidad.unidad_contenido.elementos_simbolicos_arte.pos - 1)} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                          <img style={{ height: '600px', marginLeft: '-94%', rotate: '180deg' }} src='/img/flecha_azul.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                        </div>
                        {unidad.unidad_contenido.elementos_simbolicos_arte.pos < ejeLength.simbolico && <div onClick={() => handleScrollSub('right', unidad.unidad_contenido.elementos_simbolicos_arte.pos + 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                          <img style={{ height: '600px', marginLeft: '79%', rotate: '0deg' }} src='/img/flecha_azul.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                        </div>}
                      </div>
                    ))}

                    <div style={{ backgroundColor: 'rgb(195 250 255)', width: '100%', height: '100%', top: `calc(100vh * ${unidad && unidad.unidad_contenido.elementos_simbolicos_arte && unidad.unidad_contenido.elementos_simbolicos_arte.imgs_elementos_simbolicos_artes.length})`, left: `calc(100vw * ${unidad.unidad_contenido.elementos_simbolicos_arte.pos})` }} className='br-15 p-2 pt-5 div5'>
                      <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Signos y símbolos</p>
                      {unidad && unidad.unidad_contenido.elementosSimbolicosArteId != null && <>
                        <p className='fw-600' style={{ textAlign: 'center', color: '#01E6FD' }}>OBRA DE ARTE</p>
                        <div style={{ height: '55vh', width: '100%', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                          <div className="fs-18 fw-600" style={{ textAlign: 'center' }}>{unidad.unidad_contenido.elementos_simbolicos_arte.titulo}</div>
                          <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                          <div style={{ textAlign: 'center' }}>{`${unidad.unidad_contenido.elementos_simbolicos_arte.autor} ${unidad.unidad_contenido.elementos_simbolicos_arte.fecha !== "" ? '(' + unidad.unidad_contenido.elementos_simbolicos_arte.fecha + ')' : ''}`}
                          </div>
                          <div style={{ overflowY: 'auto', maxHeight: '45vh' }}>
                            {unidad.unidad_contenido.elementos_simbolicos_arte.explicacion}
                          </div>
                          <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                            <img src='/img/Compartir_azul.png' className='he-50 pointer' onClick={() => compartir(`${unidad.unidad_contenido.elementos_simbolicos_arte.titulo} \n ${unidad.unidad_contenido.elementos_simbolicos_arte.autor} (${unidad.unidad_contenido.elementos_simbolicos_arte.fecha}) \n ${unidad.unidad_contenido.elementos_simbolicos_arte.explicacion}`)} />
                          </div>
                        </div>
                      </>}
                      {unidad && unidad.unidad_contenido.elementos_simbolicos_arte && unidad.unidad_contenido.elementos_simbolicos_arte.imgs_elementos_simbolicos_artes.length > 0 && <div onClick={() => handleScrollSubSub('top', -(unidad.unidad_contenido.elementos_simbolicos_arte.imgs_elementos_simbolicos_artes.length - 1))} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                        <img style={{ width: '500px', marginTop: '-132%', rotate: '270deg' }} src='/img/flecha_azul.png' />
                        <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                      </div>}
                      <div onClick={unidad.unidad_contenido.elementos_simbolicos_arte.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('left', unidad.unidad_contenido.elementos_simbolicos_arte.pos - 1)} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                        <img style={{ height: '600px', marginLeft: '-94%', rotate: '180deg' }} src='/img/flecha_azul.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                      </div>
                      {unidad.unidad_contenido.elementos_simbolicos_arte.pos < ejeLength.simbolico && <div onClick={() => handleScrollSub('right', unidad.unidad_contenido.elementos_simbolicos_arte.pos + 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                        <img style={{ height: '600px', marginLeft: '79%', rotate: '0deg' }} src='/img/flecha_azul.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                      </div>}
                    </div>

                  </div>}

                {/* Cuento*/}
                {unidad && unidad.unidad_contenido.elementosSimbolicosCuentoId != null && <div style={{ width: '100%' }}>
                  <div style={{ backgroundColor: 'rgb(195 250 255)', width: '100%', height: '100%', top: `calc(100vh * 0)`, left: `calc(100vw * ${unidad.unidad_contenido.elementos_simbolicos_cuento.pos})` }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Signos y símbolos</p>
                    <p className='fw-600' style={{ textAlign: 'center', color: '#01E6FD' }}>CUENTO</p>
                    <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                      <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.elementos_simbolicos_cuento.titulo}</p>
                      <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>

                      <div style={{ textAlign: 'center', overflowY: 'auto' }}>{unidad.unidad_contenido.elementos_simbolicos_cuento.texto}</div>
                      <a href={unidad.unidad_contenido.elementos_simbolicos_cuento.enlace} target='blank' className='mt-4'><button style={{ backgroundColor: '#01E6FD', border: 'none', borderRadius: '5px' }} className='p-2 pe-4 ps-4'>
                        ABRIR PÁGINA
                      </button></a>
                    </div>
                    <div onClick={unidad.unidad_contenido.elementos_simbolicos_cuento.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('left', unidad.unidad_contenido.elementos_simbolicos_cuento.pos - 1)} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                      <img style={{ height: '600px', marginLeft: '-94%', rotate: '180deg' }} src='/img/flecha_azul.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                    </div>
                    {unidad.unidad_contenido.elementos_simbolicos_cuento.pos < ejeLength.simbolico && <div onClick={() => handleScrollSub('right', unidad.unidad_contenido.elementos_simbolicos_cuento.pos + 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                      <img style={{ height: '600px', marginLeft: '79%', rotate: '0deg' }} src='/img/flecha_azul.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                    </div>}
                  </div>
                </div>}

                {/* Poesía*/}
                {unidad && unidad.unidad_contenido.elementosSimbolicosPoesiaId != null &&
                  <div style={{ width: '100%' }}>
                    {unidad &&
                      <div style={{ backgroundColor: 'rgb(195 250 255)', width: '100%', height: '100%', top: `calc(100vh * 0)`, left: `calc(100vw * ${unidad.unidad_contenido.elementos_simbolicos_poesia.pos})` }} className='br-15 p-2 pt-5 div5'>
                        <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Signos y símbolos</p>

                        <p className='fw-600' style={{ textAlign: 'center', color: '#01E6FD' }}>POESÍA</p>
                        <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                          <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.elementos_simbolicos_poesia.titulo}</p>
                          <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>

                          <div style={{ textAlign: 'center', overflowY: 'auto' }}>{unidad.unidad_contenido.elementos_simbolicos_poesia.texto.split('\n').map((line, index) => (<>
                            {line}
                            <br /></>))}</div>
                          <a href={unidad.unidad_contenido.elementos_simbolicos_poesia.enlace} target='blank' className='mt-4'><button style={{ backgroundColor: '#01E6FD', border: 'none', borderRadius: '5px' }} className='p-2 pe-4 ps-4'>
                            ABRIR PÁGINA
                          </button></a>
                        </div>


                        <div onClick={unidad.unidad_contenido.elementos_simbolicos_poesia.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('left', unidad.unidad_contenido.elementos_simbolicos_poesia.pos - 1)} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                          <img style={{ height: '600px', marginLeft: '-94%', rotate: '180deg' }} src='/img/flecha_azul.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                        </div>
                      </div>}
                  </div>}

              </div>
            </div>

            {/*Recursos - verde */}

            <div className="div5 p-3" style={{ backgroundColor: '#02F4A9' }}>
              <div className={`contentUnidad ${subPosition === 5 ? 'down' : ''} ${subPosition === 6 ? 'up' : ''} ${subPosition === 7 ? 'right' : ''} ${subPosition === 8 ? 'left' : ''}`} style={{
                height: '100%',
                width: '100%',
                ...(subPosition === 5 ? { transform: `translateY(calc(-100vh * ${slideWanted}))` } : {}),
                ...(subPosition === 6 ? { transform: `translateY(calc(-100vh * ${slideWanted}))` } : {}),
                ...(subPosition === 7 ? { transform: `translate(calc(-100vw * ${slideWanted}), calc(${subSubPosition == 6 ? '100vh' : '100vh'} * ${slideSubWanted}))` } : {}),
                ...(subPosition === 8 ? { transform: `translate(calc(-100vw * ${slideWanted}), calc(${subSubPosition == 6 ? '100vh' : '100vh'} * ${slideSubWanted}))` } : {}),
                ...(subPosition === 0 ? { transform: `translate(calc(0vw * ${slideWanted}), calc(${subSubPosition == 6 ? '100vh' : '100vh'} * ${slideSubWanted}))` } : {})

              }}>

                {/* Infografia*/}
                {unidad && unidad.unidad_contenido.recursosInfografiaId != null && unidad.unidad_contenido.recursos_infografia.imgs_recursos_infografia.length > 0 && <div style={{ width: '100%' }}>
                  {unidad.unidad_contenido.recursos_infografia.imgs_recursos_infografia.map((r, i) => (
                    <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${i})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_infografia.pos})` }} className='br-15 p-2 pt-5 div5'>
                      <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>
                      {unidad && unidad.unidad_contenido.recursosInfografiaId != null && <>
                        <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>INFOGRAFÍA</p>
                        <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                          <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.recursos_infografia.nombre}</p>
                          {r.imagen != null && <img src={`${rutaArchivos}${r.imagen.uri}`} style={{ height: '50vh' }} className='br-15' />}
                          <div style={{ textAlign: 'center', overflowY: 'auto' }}>{r.texto}</div>
                        </div>
                      </>}
                      {i != 0 && <div onClick={() => handleScrollSubSub('top', i - 1)} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                        <img style={{ width: '500px', marginTop: '-132%', rotate: '90deg' }} src='/img/flecha_verde.png' />
                        <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                      </div>}
                      {unidad && i < unidad.unidad_contenido.recursos_infografia.length - 1 && <div onClick={() => handleScrollSubSub('down', i + 1)} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                        <img style={{ width: '500px', marginBottom: '-132%', rotate: '270deg' }} src='/img/flecha_verde.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ marginBottom: '-8px', position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

                      </div>}
                      {unidad.unidad_contenido.recursos_infografia.pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_infografia.pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                        <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                      </div>}
                      <div onClick={() => handleScroll('initial')} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                        <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                      </div>
                    </div>
                  ))}
                </div>}

                {/* Video */}
                {unidad && unidad.unidad_contenido.recursosVideoId != null && <div style={{ width: '100%' }}>
                  <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${0})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_video.pos})` }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>
                    <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>VIDEO</p>
                    <div style={{ width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 pb-2 j-center'>
                      <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.recursos_video.titulo}</p>
                      <iframe width="560" height="315" src={`https://www.youtube.com/embed/${unidad.unidad_contenido.recursos_video.video_uri}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                    <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                      <img src='/img/Compartir_verde.png' className='he-50 pointer' onClick={() => compartir(`https://www.youtube.com/watch?v=${unidad.unidad_contenido.recursos_video.video_uri}`)} />
                    </div>
                    {unidad.unidad_contenido.recursos_video.pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_video.pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                      <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                    </div>}
                    <div onClick={unidad.unidad_contenido.recursos_video.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('right', unidad.unidad_contenido.recursos_video.pos - 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                      <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                    </div>
                  </div>
                </div>}

                {/* Película*/}
                {unidad && unidad.unidad_contenido.recursosPeliculaId != null &&
                  <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${0})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_pelicula.pos})` }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>

                    <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>PELÍCULA</p>
                    <div style={{ height: '50vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 pb-2 j-center'>
                      <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.recursos_pelicula.titulo}</p>
                      <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                      <p style={{ textAlign: 'center' }} >{unidad.unidad_contenido.recursos_pelicula.descripcion}</p>
                    </div>
                    <div className='flex j-center mt-2 a-center' style={{ columnGap: '15px' }}>
                      {unidad.unidad_contenido.recursos_pelicula.enlace != "" && <img src='/img/Pelicula.png' className='pointer' style={{ height: '35px' }} onClick={() => compartir(unidad.unidad_contenido.recursos_pelicula.enlace)} />
                      }<img src='/img/Compartir_verde.png' className='he-50 pointer' onClick={() => compartir(`${unidad.unidad_contenido.recursos_pelicula.titulo} \n ${unidad.unidad_contenido.recursos_pelicula.descripcion}`)} />
                    </div>


                    {unidad.unidad_contenido.recursos_pelicula.pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_pelicula.pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                      <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                    </div>}
                    <div onClick={unidad.unidad_contenido.recursos_pelicula.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('right', unidad.unidad_contenido.recursos_pelicula.pos - 1)} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                      <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                    </div>
                  </div>
                }


                {/* Canción*/}
                {unidad && unidad.unidad_contenido.recursos_cancions.length > 0 != null &&
                  <div style={{ width: '100%' }}>
                    {unidad.unidad_contenido.recursos_cancions.map((r, i) => (
                      <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${0})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_cancions[0].pos})` }} className='br-15 p-2 pt-5 div5'>
                        <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>
                        {unidad && unidad.unidad_contenido.recursosInfografiaId != null && <>
                          <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>CANCIÓN</p>
                          <div style={{ height: '50vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 pb-2 j-center'>
                            <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{r.titulo}</p>
                            <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                            <p style={{ textAlign: 'center' }} >{r.explicacion}</p>
                            <CustomAudioPlayer audioSrc={`${rutaArchivos}${r.audio}`} controlsColor={'#02F4A9'} progressBarColor={'#02F4A9'} title={""} lyrics={""} colorString={"verde"} />

                          </div>
                        </>}
                        {i != 0 && <div onClick={() => handleScrollSubSub('top', i - 1)} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                          <img style={{ width: '500px', marginTop: '-132%', rotate: '90deg' }} src='/img/flecha_verde.png' />
                          <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                        </div>}
                        {i < unidad.unidad_contenido.recursos_cancions.length - 1 && <div onClick={() => handleScrollSubSub('down', i + 1)} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                          <img style={{ width: '500px', marginBottom: '-132%', rotate: '270deg' }} src='/img/flecha_verde.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ marginBottom: '-8px', position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

                        </div>}

                        {unidad.unidad_contenido.recursos_cancions[0].pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_cancions[0].pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                          <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                        </div>}
                        <div onClick={unidad.unidad_contenido.recursos_cancions[0].pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('right', -(unidad.unidad_contenido.recursos_cancions[0].pos - 1))} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                          <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                          <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                        </div>
                      </div>
                    ))}
                  </div>
                }

                {/* Actividad */}
                {unidad && unidad.unidad_contenido.recursosActividadId != null &&
                  <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${0})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_actividad.pos})` }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>
                    {unidad && unidad.unidad_contenido.recursosInfografiaId != null && <>
                      <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>ACTIVIDAD</p>
                      <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                        <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.recursos_actividad.titulo}</p>
                        <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                        <p style={{ textAlign: 'center' }} >{unidad.unidad_contenido.recursos_actividad.explicacion}</p>
                      </div>
                    </>}
                    {unidad.unidad_contenido.recursos_actividad.pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_actividad.pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                      <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                    </div>}
                    <div onClick={unidad.unidad_contenido.recursos_actividad.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('right', -(unidad.unidad_contenido.recursos_actividad.pos - 1))} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                      <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                    </div>
                  </div>
                }

                {/* Santo */}
                {unidad && unidad.unidad_contenido.recursosSantoId != null &&
                  <>
                    {unidad.unidad_contenido.recursos_santo.imgs_recursos_santos.length > 0 && (
                      <>{unidad.unidad_contenido.recursos_santo.imgs_recursos_santos.map((r, i) => (
                        <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${i})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_santo.pos})` }} className='br-15 p-2 pt-5 div5'>
                          <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>

                          <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>SANTO</p>
                          <div style={{ width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                            <img src={`${rutaArchivos}${r.imagen.uri}`} style={{ height: '50vh' }} className='br-15' />
                            <p style={{ textAlign: 'center' }} className='fs-18 fw-600 mt-2'>{unidad.unidad_contenido.recursos_santo.santo}</p>
                          </div>
                          {/* <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                            <img src='/img/Descargar_verde.png' className='he-50 pointer' onClick={() => downloadImage(`${rutaArchivos}${r.imagen.uri}`)} />
                          </div> */}
                          {i != 0 && <div onClick={() => handleScrollSubSub('top', i - 1)} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                            <img style={{ width: '500px', marginTop: '-132%', rotate: '90deg' }} src='/img/flecha_verde.png' />
                            <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                          </div>}
                          {i < unidad.unidad_contenido.recursos_santo.imgs_recursos_santos.length && <div onClick={() => handleScrollSubSub('down', -(i + 1))} style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} >
                            <img style={{ width: '500px', marginBottom: '-132%', rotate: '270deg' }} src='/img/flecha_verde.png' className='pointer' />
                            <img src="/img/Desliza.png" className='pointer' style={{ marginBottom: '-8px', position: 'absolute', height: '40px', zIndex: '1200', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }} />

                          </div>}
                          {unidad.unidad_contenido.recursos_santo.pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_santo.pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                            <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                            <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                          </div>}
                          <div onClick={unidad.unidad_contenido.recursos_santo.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('right', -(unidad.unidad_contenido.recursos_santo.pos - 1))} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                            <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                            <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                          </div>
                        </div>))}
                      </>)}

                    <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${unidad.unidad_contenido.recursos_santo.imgs_recursos_santos.length})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_santo.pos})` }} className='br-15 p-2 pt-5 div5'>
                      <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>

                      <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>SANTO</p>
                      <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                        <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.recursos_santo.santo}</p>
                        <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>
                        <p style={{ textAlign: 'center' }} >{unidad.unidad_contenido.recursos_santo.texto}</p>
                      </div>

                      {unidad.unidad_contenido.recursos_santo.imgs_recursos_santos.length > 0 && <div onClick={() => handleScrollSubSub('top', -(unidad.unidad_contenido.recursos_santo.imgs_recursos_santos.length - 1))} style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', overflow: 'hidden' }} className='pointer'>
                        <img style={{ width: '500px', marginTop: '-132%', rotate: '90deg' }} src='/img/flecha_verde.png' />
                        <img src="/img/Desliza.png" className='pointer' style={{ marginTop: '-8px', position: 'absolute', height: '40px', zIndex: '1200', top: '0px', left: '42%', transform: 'rotate(180deg) translateX(-50%)', }} />

                      </div>}
                      {unidad.unidad_contenido.recursos_santo.pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_santo.pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                        <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                      </div>}
                      <div onClick={unidad.unidad_contenido.recursos_santo.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('right', -(unidad.unidad_contenido.recursos_santo.pos - 1))} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                        <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                        <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                      </div>
                    </div>
                  </>}

                {/* Oración */}
                {unidad && unidad.unidad_contenido.recursosOracionId != null &&
                  <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${0})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_oracion.pos})` }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>

                    <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>ORACIÓN</p>
                    <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                      <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.recursos_oracion.titulo}</p>
                      <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>

                      {unidad.unidad_contenido.recursos_oracion.subtitulo != "" && <p style={{ textAlign: 'center', color: 'grey' }} className='fs-16 fw-600'>{unidad.unidad_contenido.recursos_oracion.subtitulo}</p>
                      }
                      <p style={{ textAlign: 'center' }} >{unidad.unidad_contenido.recursos_oracion.texto}</p>
                      <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                        <img src='/img/Compartir_verde.png' className='he-50 pointer' onClick={() => compartir(`${unidad.unidad_contenido.recursos_oracion.titulo} \n ${unidad.unidad_contenido.recursos_oracion.subtitulo} \n ${unidad.unidad_contenido.recursos_oracion.texto}`)} />
                      </div>
                    </div>

                    {unidad.unidad_contenido.recursos_oracion.pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_oracion.pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                      <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />


                    </div>}
                    <div onClick={unidad.unidad_contenido.recursos_oracion.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('right', -(unidad.unidad_contenido.recursos_oracion.pos - 1))} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                      <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                    </div>
                  </div>
                }

                {/* Salmo */}
                {unidad && unidad.unidad_contenido.recursosSalmoId != null &&
                  <div style={{ backgroundColor: 'rgb(209 255 241)', width: '100%', height: '100%', top: `calc(100vh * ${0})`, left: `calc(-100vw * ${unidad.unidad_contenido.recursos_salmo.pos})` }} className='br-15 p-2 pt-5 div5'>
                    <p className='fw-600 mb-1' style={{ textAlign: 'center' }}>Recursos</p>

                    <p className='fw-600' style={{ textAlign: 'center', color: '#02F4A9' }}>SALMO</p>
                    <div style={{ height: '55vh', width: '100%', overflowY: 'auto', flexDirection: 'column' }} className='flex a-center p-5 pt-2 pb-2  pt-2 j-center'>
                      <p style={{ textAlign: 'center' }} className='fs-18 fw-600'>{unidad.unidad_contenido.recursos_salmo.titulo}</p>
                      <div style={{ height: '1px', backgroundColor: 'black', width: '100%' }} className='mb-2'></div>

                      <p style={{ textAlign: 'center', color: 'grey' }} className='fs-16 fw-600'>{unidad.unidad_contenido.recursos_salmo.cita}</p>

                      <p style={{ textAlign: 'center' }} >{unidad.unidad_contenido.recursos_salmo.texto}</p>
                      {unidad.unidad_contenido.recursos_salmo.audio != null &&
                        <CustomAudioPlayer audioSrc={`${rutaArchivos}${unidad.unidad_contenido.recursos_salmo.audio.audio}`} controlsColor={'#02F4A9'} progressBarColor={'#02F4A9'} title={unidad.unidad_contenido.recursos_salmo.titulo} lyrics={""} colorString={"verde"} />
                      }
                      {unidad.unidad_contenido.recursos_salmo.youtube != "" &&
                        <a href={`${unidad.unidad_contenido.recursos_salmo.youtube}`} target='blank'><button style={{ backgroundColor: '#02F4A9', border: 'none', borderRadius: '5px' }} className='p-2 pe-4 ps-4 mb-3 mt-4'>
                          VER EN YOUTUBE
                        </button></a>}

                      <div className='flex j-center mt-2' style={{ columnGap: '15px' }}>
                        <img src='/img/Compartir_verde.png' className='he-50 pointer' onClick={() => compartir(`${unidad.unidad_contenido.recursos_salmo.titulo} \n ${unidad.unidad_contenido.recursos_salmo.cita} \n ${unidad.unidad_contenido.recursos_salmo.texto}`)} />
                      </div>
                    </div>

                    {unidad.unidad_contenido.recursos_salmo.pos < ejeLength.recursos && <div onClick={() => handleScrollSub('left', -(unidad.unidad_contenido.recursos_salmo.pos + 1))} style={{ position: 'absolute', left: '0px', top: '50%', transform: 'translateY(-50%)' }} >
                      <img style={{ height: '600px', marginLeft: '-94%', rotate: '0deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', left: '0px', top: '47.5%', transform: 'rotate(90deg) translateY(-50%)', marginLeft: '-22px' }} />

                    </div>}
                    <div onClick={unidad.unidad_contenido.recursos_salmo.pos == 0 ? () => handleScroll('initial') : () => handleScrollSub('right', -(unidad.unidad_contenido.recursos_salmo.pos - 1))} style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)', width: '100px' }} >
                      <img style={{ height: '600px', marginLeft: '79%', rotate: '180deg' }} src='/img/flecha_verde.png' className='pointer' />
                      <img src="/img/Desliza.png" className='pointer' style={{ position: 'absolute', height: '30px', zIndex: '1200', right: '0px', top: '47.5%', transform: 'rotate(270deg) translateY(-50%)', marginRight: '-22px' }} />

                    </div>
                  </div>
                }

              </div>
            </div>


          </div>
          :
          <div className='flex j-center a-center mt-3' style={{ height: '100vh' }}>
            <Loading />
          </div>
        }
        {showAlert && (
          <div style={{ width: '100%', position: 'absolute', bottom: '30px' }} className='flex j-center'>
            <Alert variant="success" style={{ zIndex: '1000' }}>
              ¡Copiado al portapapeles!
            </Alert>
          </div>
        )}

        <Modal show={showModal} onHide={handleClose} centered style={{ zIndex: 2000 }}>
          <Modal.Header closeButton className='gradientBack'>
            <Modal.Title>Bibliografía</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {unidad && unidad.unidad_contenido.bibliografia.map((r, i) => (
              <div>
                <p className='mb-1'>{r.texto}</p>
                <a href={r.enlace} target='blank'><button style={{ backgroundColor: '#02F4A9', border: 'none', borderRadius: '5px' }} className='p-2 pe-4 ps-4 mb-3'>
                  ABRIR PÁGINA
                </button></a>
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>

          {showAlert && (
            <div style={{ width: '100%', position: 'absolute', bottom: '30px' }} className='flex j-center'>
              <Alert variant="success" style={{ zIndex: '2300' }}>
                ¡Copiado al portapapeles!
              </Alert>
            </div>
          )}
        </Modal>

        <Modal show={showModalActions} onHide={handleCloseActions} centered style={{ zIndex: 2000 }}>
          <Modal.Body>
            <div className='' style={{ columnGap: '15px' }}>
              <div className='flex a-center mb-2' style={{ columnGap: '8px' }}>
                {isSaved ? <FontAwesomeIcon icon={solidBookmark} style={{ fontSize: '25px' }} className='pointer' onClick={postGuardarUnidad} />
                  : <FontAwesomeIcon icon={regularBookmark} style={{ fontSize: '25px' }} className='pointer' onClick={postGuardarUnidad} />
                }
                <p className='mb-0 ps-2'>Guardar unidad</p>
              </div>
              <div className='flex a-center'>
                <img style={{ height: '50px', marginLeft: '-10px' }} src='/img/Bibliografía.png' className='pointer' onClick={handleShow} />
                <p className='mb-0'>Bibliografía</p>
              </div>
              <div className='flex a-center'>
                <img style={{ height: '50px', marginLeft: '-10px' }} src='/img/Compartir.png' onClick={() => compartir(`https://unit.fasta.org/unidad?id=${getParams()}`)} className='pointer' />
                <p className='mb-0'>Compartir</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseActions}>
              Cerrar
            </Button>
          </Modal.Footer>

          {showAlert && (
            <div style={{ width: '100%', position: 'absolute', bottom: '30px' }} className='flex j-center'>
              <Alert variant="success" style={{ zIndex: '2300' }}>
                ¡Copiado al portapapeles!
              </Alert>
            </div>
          )}
        </Modal>

      </div>
    </div >
  );
}

export default Unidad;
