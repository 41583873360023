import constantes from '../controlador/constantes.js';

const urlApi = constantes.dev ? constantes.dev_api_uri : constantes.prod_api_uri;

const urlWebServices = {
    crearEje: urlApi + "api/ejes/",
    obtenerEjes: urlApi + "api/ejes/",
    obtenerSecciones: urlApi + "api/secciones-dptos/",
    crearUnidad: urlApi + "api/ejes/unidades/",
    obtenerMarchas: urlApi + "api/marchas/",
    obtenerUnidades: urlApi + "api/ejes/unidades/",
    obtenerUnidad: urlApi + "api/ejes/unidades/",
    eliminarUnidad: urlApi + "api/ejes/unidades/",
    modificarUnidad: urlApi + "api/ejes/unidades/",
    login: urlApi + "api/usuarios/login",
    signup: urlApi + "api/usuarios/signup",
    obtenerUsuarios: urlApi + "api/usuarios/all",
    habilitarUsuario: urlApi + "api/usuarios/habilitar/",
    obtenerRucas: urlApi + "api/rucas/",
    obtenerNovedades: urlApi + "api/novedades/",
    buscarUnidad: urlApi + "api/ejes/unidades/search/",
    obtenerMiData: urlApi + "api/usuarios/mydata/",
    obtenerUnidadesGuardadas: urlApi + "api/ejes/unidades/favoritos/",
    guardarUnidad: urlApi + "api/ejes/unidades/favoritos/",
    obtenerUnidadesAdmin: urlApi + "api/ejes/unidades/all",
}

export default urlWebServices;