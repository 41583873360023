import React, {useEffect} from 'react'

/* export default function UserController(){

    useEffect(() => {
        validarUsuario();
    }, []);
    
    const validarUsuario = function(){
        const token = localStorage.getItem("user-token");
        if(token !== undefined && token !== null){
            if(token.length > 10){
                //Usuario está logueado

                if(window.location.href === "https://unit.fasta.org/" || window.location.href  === "http://localhost:3000/"){
                    //Estoy en el inicio
                    window.location.href = "/unidades";
                }
                
            }else{
                window.location.href = "/login";
            }
        }else{
            window.location.href = "/login";
        }
    }

    return (
        <div></div>
    );
}
 */
export const validarUsuario = function(){
    const token = localStorage.getItem("user-token");
    if(token !== undefined && token !== null){
        if(token.length > 10){
            //Usuario está logueado

            /*  if(window.location.href === "https://unit.fasta.org/" || window.location.href  === "http://localhost:3000/"){
                //Estoy en el inicio
                window.location.href = "/";
            }  */
            
        }else{
            logout();
            window.location.href = "/login";
        }
    }else{
        logout();
    }
}

export const logout = function (){
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-nombre");
    localStorage.removeItem("user-apellido");
    localStorage.removeItem("user-id");
    localStorage.removeItem("user-role");
    window.location.href = '/login';
}

export const getUserToken = () => {
    return localStorage.getItem("user-token");
} 

export const getUserRole = () => {
    return localStorage.getItem("user-role");
} 

export const getUserId = () => {
    return localStorage.getItem("user-id");
} 

export default function UserController(){

    useEffect(() => {
        validarUsuario();
    }, []);
    
    const validarUsuario = function(){
        const token = localStorage.getItem("user-token");
        if(token !== undefined && token !== null){
            if(token.length > 10){
                //Usuario está logueado

                /* if(window.location.href === "https://unit.fasta.org/" || window.location.href  === "http://localhost:3000/"){
                    //Estoy en el inicio
                    window.location.href = "/";
                } */

                
            }else{
                window.location.href = "/login";
            }
        }else{
            window.location.href = "/login";
        }
    }

    return (
        <div></div>
    );
}
