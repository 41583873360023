import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';

function NotFound({iconFa, text, classExtra}) {

    return (
        <div className={`notFoundContainer ${classExtra}`}>
            <Row className='mb-3 mt-2 j-center'>
                <FontAwesomeIcon icon={iconFa} className='notFoundIcon' style={{textAlign:'center', fontSize:'60px'}}/>
            </Row>
            <Row>
               <p className='mb-0' style={{textAlign:'center'}}>{text}</p>             
            </Row>
        </div>
    )
}

export default NotFound