import React, {useEffect, useState} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Col, Table} from "react-bootstrap";
import {get} from '../../../controlador/index.controller';

export default function Notifications(){
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        document.title = "Unit - Notificaciones";
        getNotifications();
        console.log();
    }, []);

    const getNotifications = async function(){
        const token = localStorage.getItem("user-token")
        let request = await get("api/usuarios/notifications", token);
        const response = await request.json();
        setNotifications(response);
    }

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1>Notificaciones</h1>
                    </Col>
                    <Col style={{textAlign: "right"}} className="mt-2">
                        
                    </Col>
                </Row>

                <Row>
                    <Table striped borderless hover style={{fontSize:13}}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Título</th>
                                <th>Cuerpo</th>
                                <th>Usuarios</th>
                                <th>Remitente</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notifications.map((n) => (
                                <tr>
                                    <td style={{verticalAlign: "middle", width: 50}}>{n.id}</td>
                                    <td style={{verticalAlign: "middle"}}>{n.titulo}</td>
                                    <td style={{verticalAlign: "middle"}}>{n.cuerpo}</td>
                                    <td style={{verticalAlign: "middle"}}>{n.usuarios}</td>
                                    <td style={{verticalAlign: "middle"}}>{`${n.usuario.nombre} ${n.usuario.apellido}`}</td>
                                    <td style={{verticalAlign: "middle"}}>{n.createdAt}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </div>
    );
}